import { Input } from 'antd';
import * as React from 'react';

import { FieldWrapper, FieldWrapperPassThroughProps } from '../FieldWrapper';
import { InputProps } from '../types';

export type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'number' | 'color' | 'textarea';
} & InputProps;

export const InputField = React.forwardRef(
  (
    { type = 'text', label, className, error, parentClassName, ...props }: InputFieldProps,
    ref: any
  ) => {
    return (
      <FieldWrapper label={label} error={error} parentClassName={parentClassName}>
        <Input
          type={type}
          className={`${className} ${error && 'input-error'}`}
          {...props}
          ref={ref}
        />
      </FieldWrapper>
    );
  }
);

InputField.displayName = 'InputField';
