const storagePrefix = 'xstak_studio_';

const storage = {
  getToken: () => {
    return window.localStorage.getItem(`${storagePrefix}token`);
  },
  getRealm: () => window.localStorage.getItem('realm'),
  setRealm: (data: string) => window.localStorage.setItem('realm', data),
  setToken: (token: string) => {
    window.localStorage.setItem(`${storagePrefix}token`, token);
  },
  setRefreshToken: (refreshToken: string) => {
    window.localStorage.setItem(`${storagePrefix}refresh`, refreshToken);
  },
  getRefreshToken: () => window.localStorage.getItem(`${storagePrefix}refresh`),
  clearToken: () => {
    window.localStorage.removeItem(`${storagePrefix}token`);
    window.localStorage.removeItem(`${storagePrefix}refresh`);
  },
  setRemember: (rememberState: any) => {
    window.localStorage.setItem(`${storagePrefix}remember`, JSON.stringify(rememberState));
  },
  setApps: (apps: Array<string>) => {
    window.localStorage.setItem(`apps`, JSON.stringify(apps));
  },
  getApps: () => {
    return JSON.parse(window.localStorage.getItem('apps') as string);
  },
  getRemember: () => {
    return JSON.parse(window.localStorage.getItem(`${storagePrefix}remember`) as string);
  },
  setHyperPermissions: (permissions: []) => {
    window.localStorage.setItem(`permissions`, JSON.stringify(permissions));
  },
  getHyperRolePermissions: () => {
    try {
      return JSON.parse(window.localStorage.getItem(`permissions`) as string);
    } catch (error) {
      return undefined;
    }
  },
};

export default storage;
