import { Drawer, DrawerProps } from 'antd';
import React from 'react';
type FormDrawerProps = {
  renderFooter?: () => React.ReactNode;
  children?: React.ReactNode;
  handleClose?: () => void;
} & DrawerProps;
export const FormDrawer = ({ children, handleClose, renderFooter, ...props }: FormDrawerProps) => {
  return (
    <Drawer destroyOnClose onClose={handleClose} footer={renderFooter && renderFooter()} {...props}>
      {children}
    </Drawer>
  );
};
