import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';

import { TreeSelect } from '../treeSelect';

type SelectControllerProps<FormValues> = {
  name: Path<FormValues>;
  defaultValue?: any;
  control: Control<FormValues>;
  rules?: RegisterOptions;
  onChange?: (x: any) => void;
  treeData: any;
  label: string;
  placeholder: string;
  disabled?: boolean;
};

export const TreeSelectController = <FormValues extends FieldValues>({
  name,
  control,
  rules,
  label,
  defaultValue,
  placeholder,
  onChange,
  treeData,
  disabled,
}: SelectControllerProps<FormValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <TreeSelect
            {...{
              treeData,
              value: field?.value,
              onChange: (value: string[]) => {
                if (onChange) {
                  onChange(value);
                }
                field.onChange(value);
                console.log(value);
              },
              placeholder,
              error,
              label,
              disabled,
            }}
          />
        );
      }}
    />
  );
};
