import { Switch as AntSwitch, SwitchProps } from 'antd';
import { FC } from 'react';

import { FieldWrapper, FieldWrapperPassThroughProps } from '@/components/Form/FieldWrapper';

export type SwitchButtonProps = SwitchProps & FieldWrapperPassThroughProps;

export const Switch: FC<SwitchButtonProps> = ({
  label,
  error,
  className,
  checked,
  disabled,
  onChange,
  parentClassName,
}) => {
  return (
    <FieldWrapper label={label} error={error} parentClassName={parentClassName}>
      <AntSwitch checked={checked} disabled={disabled} className={className} onChange={onChange} />
    </FieldWrapper>
  );
};
