import { Layout } from '../components/Layout';
import { ResetPasswordForm } from '../components/ResetPasswordForm';

export const SetNewPassword = () => {
  return (
    <Layout title="Update Your Password">
      <ResetPasswordForm />
    </Layout>
  );
};
