// import axios from 'axios';

import * as Axios from '@/lib/axios';
import storage from '@/utils/storage';

import { companiesRes, sdData, sdStoresRes } from '../constants';

import { orRolesTypes, SdData } from './hooks';
import { oeBrandLocations, oeBrands, oeRoles, oeUser } from './hooks/oeApi';

// <*--------------------XAP API's Start---------------------*>
type apps = 'sd' | 'or' | 'hypr' | 'oe' | 'xpay';
export const xapUser = (
  data: any
): Promise<{
  warnings: Array<apps>;
  message: string;
  error: {
    message: string;
    err: apps;
  }[];
}> =>
  Axios.axiosClient.post(Axios.Urls.POST.xapNewUser, data, {
    headers: { Authorization: `${storage.getToken()}` },
  });
export const fetchXapUsers = (realm: any) =>
  Axios.axiosClient.get(Axios.Urls.GET.getXapUsers + realm, {
    headers: { Authorization: `${storage.getToken()}` },
  });
export const fetchXapXPayUsers = (realm: any) =>
  Axios.axiosClient.get(Axios.Urls.GET.getXapUsers + realm + '/roles/xpay', {
    headers: { Authorization: `${storage.getToken()}` },
  });
export const fetchXapOEUsers = (realm: any) =>
  Axios.axiosClient.get(Axios.Urls.GET.getXapUsers + realm + '/roles/oe', {
    headers: { Authorization: `${storage.getToken()}` },
  });
export const xapUserStatus = (data: any) =>
  Axios.axiosClient.put(Axios.Urls.PUT.updateStatus, data, {
    headers: { Authorization: `${storage.getToken()}` },
  });
export const xapUserUpdate = (data: any) =>
  Axios.axiosClient.post(Axios.Urls.POST.xapUserUpdate, data, {
    headers: { Authorization: `${storage.getToken()}` },
  });
export const getApps = async (): Promise<{
  apps: Array<string>;
}> => {
  return Axios.axiosClient.get(Axios.Urls.GET.userRoles, {
    headers: { Authorization: `${storage.getToken()}` },
  });
};

// <*--------------------XAP API's Ends---------------------*>

// <*--------------------Hypr API's Start---------------------*>
export const getHyperRoles = (): Promise<any> => {
  return Axios.axiosClient.get(Axios.Urls.GET.hyperRoles, {
    baseURL: Axios.hyperBaseURL,
    headers: { clientid: 'unifiedPortal', Authorization: `${storage.getToken()}` },
  });
};
export const fetchHyprRoles = (params: string | unknown): Promise<any> => {
  return Axios.axiosClient.get(`/user/getAllUsers${params}&search=`, {
    baseURL: Axios.hyperBaseURL,
    headers: { clientid: 'unifiedPortal', Authorization: `${storage.getToken()}` },
  });
};
export const getBusinessUnitByCompany = (companyId: string | number) => {
  return Axios.axiosClient.get(`/businessUnit?company_id=${companyId}&clientTimeOffset=-300`, {
    baseURL: Axios.hyperBaseURL,
    headers: { clientid: 'unifiedPortal', Authorization: `${storage.getToken()}` },
  });
};
export const getAllLocations = () => {
  return Axios.axiosClient.get(`/location/getAllLocations?clientTimeOffset=-300`, {
    baseURL: Axios.hyperBaseURL,
    headers: { clientid: 'unifiedPortal', Authorization: `${storage.getToken()}` },
  });
};
export const getHyperLocations = (): Promise<any> => {
  return Axios.axiosClient.get(Axios.Urls.GET.hyperCompany, {
    baseURL: Axios.hyperBaseURL,
    headers: { clientid: 'unifiedPortal', Authorization: `${storage.getToken()}` },
  });
};

export const getAllCompanies = (): Promise<companiesRes> => {
  return Axios.axiosClient.get('/company/getAllCompanies?clientTimeOffset=-300', {
    baseURL: Axios.hyperBaseURL,
    headers: { clientid: 'unifiedPortal', Authorization: `${storage.getToken()}` },
  });
};

export const getHyperUserInfo = () =>
  Axios.axiosClient.get(Axios.Urls.GET.hyperUserInfo, {
    baseURL: Axios.hyperBaseURL,
    headers: { clientid: 'unifiedPortal', Authorization: `${storage.getToken()}` },
  });

export const getHyperPermissions = (role_id: string | number) => {
  return Axios.axiosClient.get(
    `/permission/getRolePermissionCodes?role_id=${role_id}&clientTimeOffset=-300`,
    {
      baseURL: Axios.hyperBaseURL,
      headers: { clientid: 'unifiedPortal', Authorization: `${storage.getToken()}` },
    }
  );
};

export const getAllBusinessUnits = () => {
  return Axios.axiosClient.get(`/businessUnit?clientTimeOffset=-300`, {
    baseURL: Axios.hyperBaseURL,
    headers: { clientid: 'unifiedPortal', Authorization: `${storage.getToken()}` },
  });
};

// <*--------------------Hypr API's Ends---------------------*>

// <*--------------------SD API's Start---------------------*>

export const getSDRoles = (signal: AbortSignal | undefined): Promise<any> => {
  return Axios.axiosClient.get(Axios.Urls.GET.sdRoles, {
    ...Axios.sdConfig,
    signal,
    headers: { Authorization: `Bearer ${storage.getToken()}` },
  });
};

export const getAllSdStores = (): Promise<sdStoresRes> => {
  return Axios.axiosClient.get(Axios.Urls.GET.sdStores, {
    ...Axios.sdConfig,
    headers: { Authorization: `Bearer ${storage.getToken()}` },
  });
};
export const getSDUsers = (sdData: SdData): Promise<any> => {
  const { brand_id, store_id, pageSize, page } = sdData;
  return Axios.axiosClient.get(`${Axios.Urls.GET.sdUser}?page=${page}&limit=${pageSize}`, {
    ...Axios.sdConfig,
    headers: { Authorization: `Bearer ${storage.getToken()}`, brand_id, store_id },
  });
};
export const fetchSDUserInfo = (args: Partial<sdData>) => {
  const { user_id, brand_id, store_id } = args;
  return Axios.axiosClient.get(`${Axios.Urls.GET.sdUserInfo}${user_id}`, {
    ...Axios.sdConfig,
    headers: { Authorization: `Bearer ${storage.getToken()}`, brand_id, store_id },
  });
};
// <*--------------------SD API's Ends---------------------*>

// <*--------------------OR API's Start---------------------*>

export const getOrRoles = (param: any) => {
  return Axios.axiosClient.post(
    '/api/graphql/',
    { ...param },
    { ...Axios.orConfig, headers: { Authorization: `Bearer ${storage.getToken()}` } }
  );
};

export const getOrPermission = (): Promise<orRolesTypes[]> => {
  return Axios.axiosClient.get(Axios.Urls.GET.getORPermissions, {
    ...Axios.orConfig,
    headers: { Authorization: `Bearer ${storage.getToken()}` },
  });
};
// <*--------------------OR API's Ends---------------------*>

export const getOeRoles = (): Promise<oeRoles> =>
  Axios.axiosClient.get(Axios.Urls.GET.getOERoles, {
    ...Axios.oeConfig,
    headers: { token: `${storage.getToken()}` },
  });
export const getOEBrands = (): Promise<oeBrands> =>
  Axios.axiosClient.get(Axios.Urls.GET.getOEBrands, {
    ...Axios.oeConfig,
    headers: { token: `${storage.getToken()}` },
  });
export const getOEBrandsLocations = (brand_id: number | undefined): Promise<oeBrandLocations> =>
  Axios.axiosClient.get(`configs/b/${brand_id}/locations?users=true`, {
    ...Axios.oeConfig,
    headers: { token: `${storage.getToken()}` },
  });

export const getOEusers = (): Promise<oeUser> =>
  Axios.axiosClient.get(`configs/api/v1/users`, {
    ...Axios.oeConfig,
    headers: { token: `${storage.getToken()}` },
  });
