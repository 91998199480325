/* eslint-disable no-useless-escape */
import { MailOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as z from 'zod';

import { Form, InputField } from '@/components/Form';
import { resetPassword } from '@/features/auth';
import { showNotification } from '@/utils/scripts';

import { checkWhiteSpaces } from './LoginForm';

export const ResetPasswordForm = () => {
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const search = useLocation().search;
  const authToken = new URLSearchParams(search).get('auth_token');
  const emailType = new URLSearchParams(search).get('action_type');

  type ForgetPasswordFormProps = {
    password: string;
    confirmPassword: string;
  };
  const schema = z
    .object({
      password: z.string(),
      confirmPassword: z.string(),
    })
    .superRefine((val, ctx) => {
      checkWhiteSpaces(val.password, 'password', ctx);
      checkWhiteSpaces(val.confirmPassword, 'confirmPassword', ctx);
      const exp = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&^_-])[A-Za-z\d$@$!%*#?&^_-]{8,}$/;
      const passValue = exp.test(val.password);
      const confirmValue = exp.test(val.confirmPassword);
      if (!passValue) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['password'],
          message:
            'Minimum eight characters, at least one letter, one number and one special character required',
        });
      }
      if (!confirmValue) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['confirmPassword'],
          message:
            'Minimum eight characters, at least one letter, one number and one special character required',
        });
      }
    });

  const updatePassword = async (value: any) => {
    setError('');
    setLoading(true);
    const apiCall = await resetPassword(value, authToken);
    if (apiCall.error === false) {
      setLoading(false);
      window.localStorage.clear();
      showNotification(
        'success',
        'Success',
        `${emailType !== 'accountActivation' ? 'Your password has been reset.' : 'Set Password'}`
      );
      navigate('/auth/login', { replace: true });
    } else {
      setLoading(false);
      showNotification('error', apiCall.message || apiCall.data);
    }
  };

  return (
    <div className="p-6 bg-white rounded-xl lg:w-[32vw] mx-6">
      <div className="flex flex-col justify-center items-center gap-4 w-full h-full mb-4">
        <div className="login__logo">
          <img
            src={
              'https://uploads-ssl.webflow.com/6225e3bcdcd38d2a25b1df9e/62f4f1d8b5f15173d11b5d40_logo-xstak.svg'
            }
            alt="xStak"
          />
        </div>
        <p className="text-[#262626] text-[24px] md:text-[1.5vw] font-semibold text-center mb-0 ">
          {emailType !== 'accountActivation' ? 'Reset Your Password' : 'Set New Password'}
        </p>
      </div>
      <Form<ForgetPasswordFormProps, typeof schema>
        onSubmit={(value) => {
          value.password === value.confirmPassword
            ? updatePassword(value)
            : setError('Password do not match');
        }}
        schema={schema}
        className="change-password-form"
      >
        {({ register, formState, getValues }) => (
          <>
            <>
              <fieldset>
                <InputField
                  type="password"
                  label="Password"
                  suffix={<MailOutlined className="opacity-50" />}
                  placeholder="*********"
                  error={formState.errors['password']}
                  registration={register('password', {
                    onChange: () => {
                      const value = getValues();
                      if (value.password === value.confirmPassword) {
                        setError('');
                      }
                    },
                  })}
                />
              </fieldset>

              <fieldset>
                <InputField
                  type="password"
                  label="Confirm Password"
                  suffix={<MailOutlined className="opacity-50" />}
                  placeholder="*********"
                  error={formState.errors['confirmPassword']}
                  registration={register('confirmPassword', {
                    onChange: () => {
                      const value = getValues();
                      if (value.password === value.confirmPassword) {
                        setError('');
                      }
                    },
                  })}
                />
              </fieldset>
            </>
            {error && <p className="text-red-500">{error}</p>}
            <div>
              <Button
                htmlType="submit"
                type="primary"
                loading={loading}
                className="bg-black hover:bg-black hover:border-black border-black active:border-black active:bg-black active:text-white focus:border-black focus:bg-black focus:text-white text-white hover:text-white w-full rounded h-[40px] text-[16px] md:text-[1vw] flex items-center  justify-center"
              >
                {emailType !== 'accountActivation' ? 'Reset Password' : 'Set Password'}
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
