import React from 'react';

import { SelectController } from '@/components/Form';

const OEFields: React.FC<oeFieldTypes> = ({ control, OELocations, watch }) => {
  const isAllRoleSelected = watch()?.role?.includes('oe-roles');
  console.log(isAllRoleSelected);

  return (
    <SelectController
      placeholder="Select OE Locations"
      label="Select OE Locations"
      name="oeLocations"
      isMulti={true}
      options={OELocations || []}
      fieldNames={{ label: 'label', value: 'value' }}
      isSearchable={false}
      control={control}
    />
  );
};

export default OEFields;

interface oeFieldTypes {
  control: any;
  OELocations: any;
  watch: any;
}
