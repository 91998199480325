// import { Input } from 'antd';
import clsx from 'clsx';
import * as React from 'react';
import { UseFormRegisterReturn } from 'react-hook-form';

import { EyeIcon, EyeOffIcon } from '@/assets/icons';

import { FieldWrapper, FieldWrapperPassThroughProps } from './FieldWrapper';

type InputFieldProps = FieldWrapperPassThroughProps & {
  type?: 'text' | 'email' | 'password';
  className?: string;
  registration: Partial<UseFormRegisterReturn>;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  placeholder?: string;
  autoComplete?: string;
  defaultValue?: string;
};

export const InputField = (props: InputFieldProps) => {
  const [icon, setIcon] = React.useState('eye');
  const changeIcon = () => {
    setIcon((prev) => {
      if (prev === 'eye') {
        return 'eye-off';
      } else return 'eye';
    });
  };
  const {
    type = 'text',
    label,
    className,
    registration,
    error,
    // prefix,
    suffix,
    placeholder,
    autoComplete,
    defaultValue,
  } = props;
  return (
    <FieldWrapper label={label} error={error}>
      {type === 'password' ? (
        <input
          placeholder={placeholder}
          // prefix={prefix}
          // suffix={suffix}
          type={icon === 'eye' ? 'password' : 'text'}
          className={clsx(
            'appearance-none block w-full pl-3 pr-8 py-2 border border-[#D9D9D9] rounded-sm shadow-sm placeholder-[#8C8C8C] focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
            className
          )}
          {...registration}
        />
      ) : (
        <div>
          <input
            placeholder={placeholder}
            // prefix={prefix}
            // suffix={suffix}
            type={type}
            autoComplete={autoComplete}
            defaultValue={defaultValue}
            className={clsx(
              'appearance-none block w-full pl-3 pr-8 py-2 border border-[#D9D9D9] rounded-sm shadow-sm placeholder-[#8C8C8C] focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm',
              className
            )}
            {...registration}
          />
          <div className="absolute w-6 right-[1%] bottom-[0.85rem]">{suffix}</div>
        </div>
      )}
      {type === 'password' && (
        <div
          onClick={changeIcon}
          className="absolute flex items-center justify-center passwordInput"
        >
          {icon === 'eye' ? <EyeIcon /> : <EyeOffIcon />}
        </div>
      )}
    </FieldWrapper>
  );
};
