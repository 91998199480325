/* eslint-disable no-restricted-imports */
import { useRoutes, Navigate } from 'react-router-dom';

// import { useAuth } from '@/lib/auth';
// import UserManagement from '@/features/roles/components/userManagement';
import { SetNewPassword } from '@/features/auth/routes/SetNewPassword';
import storage from '@/utils/storage';

import ProtectedRoutes from './protected';
import { publicRoutes } from './public';

export const AppRoutes = () => {
  // const auth = useAuth();

  const commonRoutes = [
    {
      path: '/',
      element: storage.getToken() ? <Navigate to="/app" /> : <Navigate to="/auth/login" />,
    },
    {
      path: '/auth/set-new-password',
      element: <SetNewPassword />,
    },
    {
      path: '*',
      element: !storage.getToken() ? <Navigate to="/auth/login" /> : <Navigate to="/app" />,
    },
  ];
  const routes = storage.getToken() ? ProtectedRoutes() : publicRoutes;
  const element = useRoutes([...commonRoutes, ...routes]);

  return <>{element}</>;
};
