import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import * as React from 'react';
import ReactDOM from 'react-dom';

import App from './App';
import './antd-button.css';
import './antd-checkbox.css';
import './antd-divider.css';
import './antd-dropdown.css';
import './antd-input.css';
import './antd-layout.css';
import './antd-menu.css';
import './antd-notification.css';
import 'antd/dist/antd.css';
import './index.scss';
import './index.css';
import './custom.scss';
import reportWebVitals from './reportWebVitals';

Sentry.init({
  dsn: 'https://e41137318ffd4f17ad4abf751803a59d@o1157378.ingest.sentry.io/5988543',
  integrations: [new BrowserTracing()],
  environment: 'stage',
  beforeBreadcrumb: (breadcrumb: any, hint: any) => {
    if (breadcrumb.category === 'xhr') {
      const data = {
        requestBody: hint.xhr.__sentry_xhr__.body,
        response: hint.xhr.response,
        responseUrl: hint.xhr.responseURL,
      };
      return { ...breadcrumb, data };
    }
    return breadcrumb;
  },

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
