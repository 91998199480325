import { Button as AntButton } from 'antd';
import { ButtonProps as AntButtonProps } from 'antd/lib/button/button';
import clsx from 'clsx';
import { FC } from 'react';
import './styles.scss';

export type ButtonProps = {
  createBtn?: boolean;
  icon?: React.ReactNode;
  children?: React.ReactNode;
} & AntButtonProps;

export const Button: FC<ButtonProps> = ({ icon, className, createBtn, ...props }) => {
  return (
    <AntButton className={clsx(`button`, className, createBtn && 'create-btn')} {...props}>
      {!!icon && icon}
      {props.children}
    </AntButton>
  );
};

Button.displayName = 'Button';
