import { TreeSelect as AntTreeSelect, TreeSelectProps } from 'antd';
import * as React from 'react';
import { FieldError } from 'react-hook-form';

import { FieldWrapper } from '../FieldWrapper';
export interface treeSelect extends TreeSelectProps {
  label: string;
  error: FieldError | undefined;
}

const { SHOW_PARENT } = AntTreeSelect;
export const TreeSelect: React.FC<treeSelect> = ({
  treeData,
  value,
  onChange,
  placeholder,
  label,
  error,
  disabled,
}) => {
  return (
    <FieldWrapper label={label} error={error}>
      <AntTreeSelect
        {...{
          treeData,
          value,
          onChange,
          treeCheckable: true,
          showCheckedStrategy: SHOW_PARENT,
          placeholder,
          style: {
            width: '100%',
          },
          disabled,
          // labelInValue: true,
        }}
      />
    </FieldWrapper>
  );
};
