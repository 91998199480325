import { axiosClient, Urls } from '@/lib/axios';

export const loginWithEmailAndPassword = async (payload: {
  username: string;
  password: string;
}) => {
  let response;
  try {
    response = await axiosClient.post(Urls.POST.signIn, payload);

    return {
      error: false,
      data: response,
      message: response.data,
      code: response.status,
    };
  } catch (e: any) {
    response = {
      error: true,
      data: (e.response && e.response.data.error) || 'Something went wrong.',
      message: (e.response && e.response.data.message) || 'Something went wrong.',
      code: e.response.status || 404,
    };
  }
  return response;
};
