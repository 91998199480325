const LogisticIcon = () => {
  return (
    <>
      <svg
        width="40"
        height="40"
        viewBox="0 0 40 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect width="40" height="40" rx="6" fill="#262626" />
        <path
          d="M17.2069 24.4545C17.2069 25.8604 16.0953 27 14.7241 27C13.3529 27 12.2414 25.8604 12.2414 24.4545M17.2069 24.4545C17.2069 23.0487 16.0953 21.9091 14.7241 21.9091C13.3529 21.9091 12.2414 23.0487 12.2414 24.4545M17.2069 24.4545H22.7931M12.2414 24.4545H11V14C11 13.4477 11.4477 13 12 13H22.7931V24.4545M28.3793 24.4545C28.3793 25.8604 27.2677 27 25.8966 27C24.5254 27 23.4138 25.8604 23.4138 24.4545M28.3793 24.4545C28.3793 23.0487 27.2677 21.9091 25.8966 21.9091C24.5254 21.9091 23.4138 23.0487 23.4138 24.4545M28.3793 24.4545H29V19.3636L25.8966 16.1818H22.7931V24.4545M23.4138 24.4545H22.7931"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default LogisticIcon;
