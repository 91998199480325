import { axiosClient, Urls } from '@/lib/axios';

export const resetPassword = async (payload: { email: string }, token: string | null) => {
  let response;
  try {
    response = await axiosClient.patch(Urls.PATCH.resetPassword, payload, {
      headers: {
        Authorization: token,
      },
    });
    return {
      error: false,
      data: response,
      message: response.data,
      code: response.status,
    };
  } catch (error: any) {
    response = {
      error: true,
      data: error?.response?.data,
      message: error.response.data.message,
      code: error.response.status || 404,
    };
  }
  return response;
};
