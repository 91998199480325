import { notification } from 'antd';
import { useQuery } from 'react-query';

import { apiRes } from '../../constants';
import {
  fetchHyprRoles,
  getAllBusinessUnits,
  getAllCompanies,
  getAllLocations,
  getHyperPermissions,
  getHyperRoles,
} from '../index';

export const useGetHyprRoles = (hyperApp: string | boolean, onSuccess: (args: any) => void) => {
  return useQuery<apiRes, Error, apiRes>(['hypr-roles', hyperApp], getHyperRoles, {
    onSuccess: (data) => {
      const hypr =
        data?.data?.roles?.length > 0
          ? data?.data?.roles
              ?.map((elem: any) => ({
                value: elem.id + '_hypr',
                title: elem.name,
                id: elem.id,
              }))
              .filter((elem) => elem.id !== 1)
          : [];

      const hyperRoles = [
        {
          title: 'Hypr Roles',
          value: 'hypr-roles',
          key: 'hypr-roles',
          children: hypr,
          disableCheckbox: true,
        },
      ];
      onSuccess(hyperRoles);
    },
    onError: (response) => {
      notification.error({ message: response?.message || 'Something went wrong.' });
    },
    enabled: !!hyperApp,
  });
};

export const useGetHyprBusinessLocations = (hyperApp: string | boolean) =>
  useQuery(['location', hyperApp], getAllLocations, {
    select: (res: any) => res.data?.locations || [],
    onError: (response: any) => {
      notification.error({ message: response?.message || 'Something went wrong.' });
    },
    enabled: !!hyperApp,
  });
export const useGetHyprTotalCompanies = (hyperApp: string | boolean) =>
  useQuery(['companies', hyperApp], getAllCompanies, {
    select: (res) => res?.data?.companies || [],
    onError: (response: any) => {
      notification.error({ message: response?.message || 'Something went wrong.' });
    },
    enabled: !!hyperApp,
  });
export const useGetHyprTotalBusinessUnits = (hyperApp: string | boolean) =>
  useQuery(['business_units', hyperApp], getAllBusinessUnits, {
    select: (res) => res?.data || [],
    onError: (response: any) => {
      notification.error({ message: response?.message || 'Something went wrong.' });
    },
    enabled: !!hyperApp,
  });

export const useHyperPermission = (userId: string | number, onSuccess: (args: any) => void) =>
  useQuery(
    ['hypr-user-permissions', userId],
    () => {
      return getHyperPermissions(userId);
    },
    {
      onSuccess: (response) => {
        onSuccess(response?.data || []);
      },
      enabled: !!userId,
      onError: (response: any) => {
        notification.error({ message: response?.message || 'Something went wrong.' });
      },
    }
  );

export const useGetHyprUsers = (
  params: string,
  isHyr: string | boolean,
  onSuccess: (args: any) => void
) => {
  return useQuery<hyperRoles>(
    ['hyper-users', params],
    ({ queryKey }) => {
      const [, queryParams] = queryKey;
      return fetchHyprRoles(queryParams);
    },
    {
      enabled: !!isHyr,
      onSuccess: (res) => {
        const { data } = res;
        const user = data?.users.map((elem) => ({ ...elem, app: 'hypr' }));
        onSuccess(user || []);
      },
    }
  );
};

export const useHyprRole = (hyperApp: string | boolean, onSuccess: (args: any) => void) => {
  useQuery<apiRes, Error, apiRes>(['hypr-roles', hyperApp], getHyperRoles, {
    onSuccess: (data) => {
      onSuccess(
        data.data.roles
          .filter((elem) => elem.id !== 1)
          .map((role: any) => ({ ...role, label: role.name, value: role.id })) || []
      );
    },
    enabled: !!hyperApp,
  });
};

type hyperRoles = {
  data: {
    users: {
      id: number;
      name: string;
      address: string;
      phone: string;
      email: string;
      cnic: string;
      cnic_picture: string;
      username: string;
      password: string;
      disabled: number;
      deleted_by: null;
      created_at: string;
      updated_at: string;
      deleted_at: null;
      role: number;
    }[];
  };
};

// TreeDataType | apiRes['data']['roles']
