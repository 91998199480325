import { Tabs } from 'antd';
import { useState } from 'react';

import storage from '@/utils/storage';

import DashboardComponent from '../components/dashboard';

const Dashboard = () => {
  const apps = storage.getApps();
  const [tabsKey, setTabsKey] = useState('1');
  const onChange = (key: any) => {
    setTabsKey(key);
  };
  const isShopifyOnboard = apps && apps.length > 0 ? apps.includes('shopify-xap-oms') : false;
  return (
    <div className="dashboard_wrapper">
      <div className="__main">
        <h4 className="h3 p-6 welcome_heading bg-white">Welcome to XStak!</h4>
        <div className="__tabs">
          <Tabs defaultActiveKey="1" onChange={onChange} className="modules-tabs">
            <Tabs.TabPane tab={'All Modules'} key={'1'}>
              <DashboardComponent tabKey={'2'} />
              <DashboardComponent tabKey={'3'} />
              {!isShopifyOnboard && <DashboardComponent tabKey={'4'} />}
            </Tabs.TabPane>
            <Tabs.TabPane tab={'Commerce Solutions'} key={'2'}>
              <DashboardComponent tabKey={tabsKey} />
            </Tabs.TabPane>
            <Tabs.TabPane tab={'Marketing Solutions'} key={'3'}>
              <DashboardComponent tabKey={tabsKey} />
            </Tabs.TabPane>
            {!isShopifyOnboard && (
              <Tabs.TabPane tab={'Payment Solutions'} key={'4'}>
                <DashboardComponent tabKey={tabsKey} />
              </Tabs.TabPane>
            )}
          </Tabs>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
