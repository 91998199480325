import React from 'react';

function Pim() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
      <rect width="40" height="40" rx="6" fill="#262626" />
      <g transform="translate(10, 10)">
        <path
          stroke="white"
          d="M16.01 20.635c-.2 0-.334.133-.334.333v.366H.666V4.194h3.361c-.166.532-.133.898-.133 1.264 0 .2.133.333.333.333h7.921c.2 0 .333-.133.333-.333 0-.366.033-.732-.133-1.264h3.361V6.49c0 .2.134.333.333.333.2 0 .333-.133.333-.333V3.86c0-.199-.133-.332-.333-.332h-3.994c-.332-.6-.865-1.065-1.53-1.398C10.45.965 9.418 0 8.187 0c-1.232 0-2.264.932-2.33 2.13-.666.3-1.165.799-1.531 1.398H.333c-.2 0-.333.133-.333.333v17.806c0 .2.133.333.333.333h15.676c.2 0 .333-.133.333-.333v-.699a.358.358 0 00-.333-.333zM6.29 2.663a.357.357 0 00.2-.4C6.49 1.398 7.222.7 8.154.7c.932 0 1.664.699 1.664 1.564-.033.2.067.333.2.4a2.689 2.689 0 011.764 2.463H4.56c0-1.065.699-2.03 1.73-2.463z"
        />
        <path
          stroke="white"
          d="M8.154 3.93a1.3 1.3 0 001.299-1.298c0-.732-.566-1.298-1.299-1.298a1.3 1.3 0 00-1.298 1.298A1.3 1.3 0 008.154 3.93zm0-1.93c.333 0 .633.266.633.632a.612.612 0 01-.633.632.632.632 0 110-1.264zM4.893 7.79c0-.199-.133-.332-.333-.332H2.297c-.2 0-.333.133-.333.333v2.43c0 .2.133.332.333.332H4.56c.2 0 .333-.133.333-.333V7.79zm-.666 2.064H2.63V8.09h1.597v1.764zm.666 2.397c0-.2-.133-.333-.333-.333H2.297c-.2 0-.333.133-.333.333v2.43c0 .2.133.332.333.332H4.56c.2 0 .333-.133.333-.333v-2.43zm-.666 2.097H2.63v-1.764h1.597v1.764zm.333 2.063H2.297c-.2 0-.333.133-.333.333v2.43c0 .2.133.332.333.332H4.56c.2 0 .333-.133.333-.332v-2.43c0-.2-.167-.333-.333-.333zm-.333 2.43H2.63v-1.764h1.597v1.764zm17.54-8.155L15.71 7.391c-.1-.066-.233-.066-.3 0-.4.2-5.225 2.796-6.024 3.229-.1.066-.166.2-.166.3v5.325c0 .1.066.233.166.266l6.024 3.794c.1.067.233.067.333 0l6.057-3.228a.35.35 0 00.167-.3v-5.791c0-.1-.067-.233-.2-.3zm-6.19-2.629l5.391 2.929-1.398.765L14.18 8.79l1.398-.732zm2.662 4.593l-5.591-3.029.799-.432 5.757 3.162v1.63l-.798.433V12.95c0-.133-.067-.267-.167-.3zm-6.29-2.663l5.425 2.963-1.797.998-5.359-3.029 1.73-.932zm-2.097 6.025v-4.56l5.358 3.029v4.926l-5.358-3.395zm6.057 3.428v-4.926l1.831-.998v1.464a.35.35 0 00.166.3c.1.066.233.066.333 0l1.465-.8a.35.35 0 00.166-.299v-1.83l1.431-.766v5.026L15.91 19.44zM10.917 8.989c0-.2-.133-.333-.333-.333h-4.76c-.199 0-.332.133-.332.333s.133.333.333.333h4.76a.334.334 0 00.332-.333zm-2.363 4.16H5.79c-.2 0-.332.134-.332.333 0 .2.133.333.332.333h2.763c.2 0 .333-.133.333-.333s-.167-.333-.333-.333zm-3.295 4.793c0 .2.133.333.333.333h4.36c.2 0 .333-.133.333-.333s-.133-.333-.333-.333h-4.36a.358.358 0 00-.333.333z"
        />
      </g>
    </svg>
  );
}

export default React.memo(Pim);
