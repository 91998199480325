import { Table as AntTable } from 'antd';
import { Key } from 'antd/lib/table/interface';
import { useState } from 'react';

import './assets/index.scss';

import { TableProps } from '../types';

import SkeletonTable from './SkeletonTable';

export const Table = ({
  columns,
  data,
  isLoading,
  showSkeleton = false,
  emptyText,
  // locale,
  pagingation: {
    totalNumberOfRecords,
    pageSize: perPageSize = 10,
    customTotalRecordsMessage,
    handlePageChange,
    show = true,
    page: current,
  },
  rowSelection: rowSelectionProps,
  expandedRowRender,
  expandIconColumnIndex,
  expandIcon,
  // expandable = false,
  ...props
}: // rowSelect:
TableProps) => {
  const [pageSize, setPageSize] = useState(perPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const onRowSelection = (selectedRowKeys: Key[], selectedRows: any) => {
    if (rowSelectionProps?.handleRowSelection)
      rowSelectionProps?.handleRowSelection(selectedRowKeys, selectedRows);
  };

  const onPageChange = (page: number, pageSize: number) => {
    setPageSize(pageSize);
    setCurrentPage(page);
    if (handlePageChange) handlePageChange(page, pageSize);
  };

  const showTotal = (total: number, range: number[]) => {
    if (customTotalRecordsMessage) {
      return customTotalRecordsMessage(total, range);
    }
    return `Showing ${1 + range[1] - range[0]} from ${total} items`;
  };

  const renderEmptyText = () => {
    if (emptyText) {
      return typeof emptyText === 'function' ? emptyText() : emptyText;
    }
    return !isLoading ? 'No Data found' : '';
  };
  return (
    <div className="table">
      {showSkeleton && isLoading ? (
        <SkeletonTable rowCount={5} columns={columns} />
      ) : (
        <AntTable
          className="table-pagination"
          dataSource={[...data.map((data: any) => ({ ...data, key: data?.id }))]}
          columns={columns}
          loading={isLoading}
          pagination={
            show
              ? {
                  total: totalNumberOfRecords,
                  showSizeChanger: true,
                  onChange: onPageChange,
                  showTotal,
                  responsive: true,
                  disabled: false,
                  pageSize,
                  current: current ? current : currentPage,
                }
              : false
          }
          locale={{
            emptyText: renderEmptyText,
          }}
          rowSelection={
            rowSelectionProps?.type && {
              type: rowSelectionProps?.type,
              onChange: onRowSelection,
            }
          }
          showSorterTooltip={false}
          expandable={expandedRowRender}
          expandIconColumnIndex={expandIconColumnIndex}
          expandIcon={expandIcon}
          // expandable={expandable}

          {...props}
        />
      )}
    </div>
  );
};
