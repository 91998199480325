import { message, notification } from 'antd';
import { AxiosError } from 'axios';
import * as React from 'react';
import { SubmitHandler, UseFormResetField } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { z } from 'zod';

import { Button, FormDrawer, PageHeader } from '@/components/Elements';
import { Form, InputController, SelectController, TreeSelectController } from '@/components/Form';
import { SwitchController } from '@/components/Form/controllers/SwitchController';
import { showNotification } from '@/utils/scripts';
import storage from '@/utils/storage';

import { getHyperUserInfo, xapUser, getAllSdStores, xapUserUpdate, xapUserStatus } from '../api';
import {
  useGetorRoles,
  useGetSDRoles,
  useGetAllApps,
  // useGetOrPermission,
  // orRolesTypes,
  useGetOeRoles,
  useGetHyprBusinessLocations,
  useGetHyprRoles,
  useGetHyprTotalCompanies,
  useGetHyprTotalBusinessUnits,
  useHyperPermission,
  useGetOeBrands,
  useGetSDUserInfo,
} from '../api/hooks';
import {
  getAppRoles,
  getCompany,
  isAppExist,
  ORRoles,
  returnSdBrands,
  RolesTitles,
  schema,
  sdData,
  setRoleData,
  treeChildren,
  TreeDataType,
} from '../constants';

import { AllUsers } from './allUser';
import { HyprFields, SDFields, OEFields } from './common';

const UserManagement = () => {
  const [sdBrands, setSdBrands] = React.useState<TreeDataType>([]);
  const [app, setApp] = React.useState<Array<string>>([]);
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false);
  const [isEdit, setIsEdit] = React.useState(false);
  const [data, setData] = React.useState<TreeDataType>([]);
  // const [role, setRole] = React.useState<any>([]);
  const [parentRole, setParentRoles] = React.useState<any>([]);
  const [btnLoading, setBtnLoading] = React.useState(false);
  const [xpayStatus, setXpayStatus] = React.useState(false);
  const [usersId, setUserId] = React.useState('');
  const [sdEditData, setSdEditData] = React.useState<Partial<sdData>>({
    brand_id: '',
    store_id: '',
    user_id: '',
  });

  const [hyperPermission, setHyperPermission] = React.useState<Array<string | number>>([]);
  // const [oeBrands] = React.useState<number>();
  // const [oeBrandId, setOEBrandId] = React.useState<number | string | undefined>();
  const [OELocations, setOELocations] = React.useState<any>([]);
  const [defaultValues, setDefaultValues] = React.useState<Partial<userID>>();
  const [refetchUser, setRefetchUser] = React.useState(false);
  const schemas = schema(isEdit);
  interface userID extends UserInputs {
    user_id: string;
    status: boolean;
  }
  type UserInputs = z.infer<typeof schemas>;
  let hyperApp = getCompany('hypr', app);
  const sdApp = getCompany('sd', app);
  const orApp = getCompany('or', app);
  const oeApp = getCompany('oe', app);

  const { data: appsData } = useGetAllApps();
  const { data: userInfo } = useQuery(['hypr-user-info'], getHyperUserInfo, {
    onError: (err: any) => showNotification('error', err),
    enabled: !!hyperApp,
  });
  React.useEffect(() => {
    if (isEdit && typeof defaultValues?.role !== 'undefined') {
      setData((prev) => {
        if (parentRole.includes('oe')) {
          return prev;
        } else {
          const role: string =
            defaultValues?.role && defaultValues?.role?.length > 0 ? defaultValues?.role[0] : '';
          const sdChild: treeChildren = [];
          prev &&
            prev[0]?.children?.forEach((elem) => {
              if (elem.value === role) {
                sdChild.push({ ...elem });
              } else {
                sdChild.push({
                  ...elem,
                  disableCheckbox: true,
                });
              }
            });
          const data = [{ ...prev[0], children: sdChild }];
          setIsDrawerOpen(true);
          return data;
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues?.role]);

  const userId = userInfo?.data?.user?.role?.id;

  useHyperPermission(userId, (appsData) => {
    setHyperPermission(appsData);
  });
  const { data: BusinessLocations = [], refetch: refetchLocations } =
    useGetHyprBusinessLocations(hyperApp);

  const { data: totalCompanies, refetch: refetchCompanies } = useGetHyprTotalCompanies(hyperApp);
  const { data: totalBusinessUnits, refetch: refetchBusiness } =
    useGetHyprTotalBusinessUnits(hyperApp);

  const createUserMutation = useMutation(xapUser);
  const updateStatus = useMutation(xapUserStatus);
  const updateMutation = useMutation(xapUserUpdate, {
    onError: (err) => {
      const error = err as AxiosError;
      if (error?.response?.data?.err?.detail) {
        showNotification('error', error?.response?.data?.err?.detail);
      }
    },
  });

  const { refetch: refetchSdBrands } = useQuery(['sd-stores', sdApp], getAllSdStores, {
    onSuccess: (res) => {
      setSdBrands(returnSdBrands(res));
    },
    enabled: !!sdApp,
  });

  const handleDrawerClose = () => {
    setRefetchUser(false);
    setIsDrawerOpen(false);
    setXpayStatus(false);
    setSdEditData({});
    setDefaultValues({});
    // setAPiLoading(false);
    setData((prev) => {
      const sdChild: treeChildren = [];
      prev &&
        prev[0]?.children?.forEach((elem) => {
          sdChild.push({
            ...elem,
            disableCheckbox: false,
          });
        });
      const data = [{ ...prev?.[0], children: sdChild }];
      return data;
    });
    if (isEdit) {
      setDefaultValues({});
      setIsEdit(false);
    }
    if (parentRole && parentRole.length === 0) {
      setSdBrands([]);
      setData([]);
      setDefaultValues({});
      setApp([]);
    }
    // setOELocations([]);
    // setSaveData(true);
  };

  // useGetOrPermission(orApp, (orPermission) => {
  //   setORPermissions(orPermission);
  // });
  const { refetch: getSDUserInfo } = useGetSDUserInfo(sdEditData, (res) => {
    const brands = res[0]?.brand_info
      .map((elem) => elem.stores.map((item) => item.id + '_' + item.brand_id + '_store'))
      .flat();
    const user = res[0]?.user_info.user_role_id;
    const role: string[] = [user + '_sd'];
    setDefaultValues((prev) => ({
      ...prev,
      sdBrands: brands,
      role,
    }));
    // setIsDrawerOpen(true);
  });
  const { refetch: refetchORRoles } = useGetorRoles(orApp, (OrData) => {
    // const isPermissionsExist = ['add_user'].every((code) =>
    //   orPermission.some((allPermissions) => allPermissions.codename === code)
    // );
    // if (isPermissionsExist) {
    if (isEdit || (parentRole && parentRole.length > 0)) {
      setData([...ORRoles(OrData)]);
    } else {
      setData((prev) => {
        return [...prev, ...ORRoles(OrData)];
      });
    }
    //   setDefaultValues((prev) => {
    //     console.log(prev);
    //     return { ...prev, role: prev?.role };
    //   });
    // }
    // } else {
    //   showNotification('error ,`This User don't have permissions to create OR User` });
    // }
  });

  const { refetch: refetchSdRoles } = useGetSDRoles(sdApp, (sdData) => {
    if (isEdit || (parentRole && parentRole.length > 0)) {
      setData([...sdData]);
    } else {
      setData((prev) => {
        return [...prev, ...sdData];
      });
    }
  });
  const { refetch: refetchHyprRoles } = useGetHyprRoles(hyperApp, (hyprData) => {
    if (isEdit || (parentRole && parentRole.length > 0)) {
      setData([...hyprData]);
    } else {
      setData((prev) => {
        return [...prev, ...hyprData];
      });
    }
  });
  const getOrRoles = oeApp && !isEdit;
  const { refetch: refetchOeRoles } = useGetOeRoles(getOrRoles, (oeRoles) => {
    if (isEdit || (parentRole && parentRole.length > 0)) {
      setData([...oeRoles]);
    } else {
      setData((prev) => {
        return [...prev, ...oeRoles];
      });
    }
  });
  const { refetch: refetchOeLocs } = useGetOeBrands(oeApp, (oeBrands) => {
    if (oeBrands.length > 0) {
      setOELocations(() => {
        return oeBrands.map((loc) => {
          return {
            label: loc.name,
            value: loc.id + '_' + loc.brandId,
          };
        });
      });
    }
  });

  const onSubmit: SubmitHandler<UserInputs> = async (formData) => {
    const statusData = {
      realm: storage.getRealm(),
      email: formData.email,
      status: formData.status,
    };
    if (xpayStatus && isEdit) {
      // if (formData.status !== defaultValues?.status) {
      updateStatus.mutate(statusData, {
        onError: () => {
          showNotification('error', 'There was error while updating user status on xap');
        },
        onSuccess(data) {
          const message: any = data;
          showNotification('success', message);
          setRefetchUser(true);
          handleDrawerClose();
        },
      });
      // }

      return;
    }
    const name: Partial<{
      firstName: string;
      lastName?: string;
    }> = {};
    if (isEdit) {
      const nameLength = formData?.name?.length || 0;
      const half = Math.ceil(nameLength / 2);
      name.firstName = formData?.name?.slice(0, half).trim();
      name.lastName = formData?.name?.slice(-half).trim();
    }
    const OeBrandId: Array<string | number> = [];
    const sdBrandID: Array<{ store_id: number | string | null; brand_id: number | string | null }> =
      [];
    const oeLocationID: Array<string | number> = [];

    formData?.oeLocations?.forEach((elem) => {
      const [_id, _brandId] = elem.split('_');
      oeLocationID.push(_id);
      OeBrandId.push(_brandId);
    });
    formData.sdBrands?.forEach((elem) => {
      sdBrands.forEach((item) => {
        if (item.value === elem) {
          if (item.children.length > 0) {
            const id: any = [];
            item.children.forEach((brand) => {
              id.push({ store_id: brand.value.split('_')[0], brand_id: brand.value.split('_')[1] });
            });
            sdBrandID.push(...id);
          } else {
            sdBrandID.push({ store_id: null, brand_id: elem.split('_')[0] });
          }
        }
      });
    });
    formData?.oeLocations
      ?.filter((elem) => elem.includes('_locs'))
      .forEach((item) => {
        const [id] = item.split('_');
        oeLocationID.push(id);
      });
    formData.sdBrands
      ?.filter((elem) => elem.includes('_store'))
      .forEach((item) => {
        const [store_id, brand_id] = item.split('_');
        sdBrandID.push({
          store_id,
          brand_id,
        });
      });
    const hyprRoles = getAppRoles(formData.role || [], '_hypr');
    const orRoles = getAppRoles(formData.role || [], '_or');
    let sdRoles = null;
    sdRoles = getAppRoles(formData.role || [], '_sd');
    const oeRoles = getAppRoles(formData.role || [], '_oe');
    const nestedOERoles =
      oeRoles.length > 0
        ? oeRoles
        : data.filter((elem) => elem.key === 'oe-roles')[0]?.children?.map((role) => role.id);

    const hyprRoleTitle = RolesTitles('hypr', hyprRoles, data);
    let SdRoleTitle: any = {};

    SdRoleTitle = RolesTitles('sd', sdRoles, data);
    const OrRoleTitle = RolesTitles('or', orRoles, data);
    const OeRoleTitle = RolesTitles('oe', nestedOERoles, data);
    // const OeRoleTitle = getAppRoles(formData.role || [], '_oe', 1);
    const roles = isEdit ? parseInt(hyprRoles.toString()) : hyprRoles.map((elem) => parseInt(elem));
    // const oeRoleTitle = data[0].children.map((elem) => {
    //   if (nestedOERoles.includes(elem.value.split('_')[0])) {
    //     return elem.title;
    //   }
    // });
    const add_brands = OeBrandId?.map((elem) =>
      typeof elem === 'string' ? parseInt(elem) : elem
    )[0];
    console.log([add_brands]);
    const finalData = {
      ...(formData?.company.includes('hypr') && {
        hyprData: {
          ...(formData.location && {
            hyprLocations: formData?.location.map((elem) => {
              return typeof elem === 'string' ? parseInt(elem) : elem;
            }),
          }),
          ...(formData?.companies && {
            companies: formData?.companies.map((elem) => {
              return typeof elem === 'string' ? parseInt(elem) : elem;
            }),
          }),
          ...(formData?.business_units && {
            business_units: formData?.business_units.map((elem) => {
              return typeof elem === 'string' ? parseInt(elem) : elem;
            }),
          }),
          hyprRoles: roles,
          ...(isEdit && { id: defaultValues?.user_id }),
          cnic: formData?.cnic?.toString() ?? '',
          phone: formData?.phone?.toString() ?? '',
          status: formData?.status,
        },
      }),
      ...(formData?.company.includes('sd') && {
        sdData: {
          outlets: sdBrandID,
          sdRoles,
          ...(isEdit && { userId: defaultValues?.user_id }),
          status: formData.status,
        },
      }),
      ...(formData?.company.includes('or') && {
        orData: {
          orRoles,
          ...(isEdit && { userId: defaultValues?.user_id }),
          status: formData.status,
        },
      }),
      ...(formData?.company.includes('oe') && {
        oeData: {
          add_roles: nestedOERoles,
          ...(!isEdit && { add_brands: [add_brands] }),
          add_locations: oeLocationID?.map((elem) =>
            typeof elem === 'string' ? parseInt(elem) : elem
          ),
          ...(isEdit && { userId: defaultValues?.user_id }),
          status: formData.status,
        },
      }),
      realm: storage.getRealm(),
      username: formData?.email.toLowerCase(),
      email: formData?.email.toLowerCase(),
      firstName: formData?.firstName,
      lastName: formData?.lastName,

      ...(isEdit && {
        ...name,
      }),

      userRoles: formData?.company,
      roleTitle: {
        ...(formData?.company.includes('hypr') && {
          ...hyprRoleTitle,
        }),
        ...(formData?.company.includes('sd') && {
          ...SdRoleTitle,
        }),
        ...(formData?.company.includes('or') && {
          ...OrRoleTitle,
        }),
        ...(formData?.company.includes('oe') && {
          oe: OeRoleTitle,
        }),
      },
    };

    if (isEdit) {
      // const statusData = {
      //   realm: storage.getRealm(),
      //   email: formData.email,
      //   status: formData.status,
      // };
      // if (formData.status !== defaultValues?.status) {
      //   await updateStatus.mutate(statusData, {
      //     onError: () => {
      //       showNotification('error', 'There was error while updating user status on xap');
      //     },
      //   });
      // }
      updateMutation.mutate(finalData, {
        onSuccess: () => {
          showNotification('success', 'User updated successfully!');
          // setIsDrawerOpen(false);
          // setIsEdit(false);
          handleDrawerClose();
          setRefetchUser(true);
        },
        onError: (err) => {
          const error = err as AxiosError;
          const data = error?.response?.data;
          const message =
            typeof data === 'object' && data !== null
              ? error?.response?.data?.message
              : error?.response?.data;
          const description =
            error?.response?.data?.err?.message ||
            error?.response?.data?.err ||
            error?.response?.data?.message;
          if (description) {
            return showNotification('error', message, description);
          } else {
            return showNotification('error', message || 'Something went wrong');
          }
        },
      });
      if (
        (formData?.company.includes('oe') || formData?.company.includes('sd')) &&
        formData.status !== defaultValues?.status
      ) {
        updateStatus.mutate(statusData, {
          onError: () => {
            showNotification('error', 'There was error while updating user status on xap');
          },
          onSuccess: (data) => {
            const dataMessage: any = data;
            message.success(dataMessage);
          },
        });
        // }

        return;
      }
    } else {
      const apps = {
        sd: 'Shopdesk',
        or: 'Omniretain',
        hypr: 'Hypr',
        oe: 'Omnichannel Engine',
        xpay: 'XPay',
      } as const;
      createUserMutation.mutate(finalData, {
        onSuccess: (data) => {
          if (data?.warnings?.length > 0) {
            for (const warning of data?.warnings) {
              console.log(apps[warning]);
              showNotification('warning', `The user already exists on ${apps[warning]}`);
            }
          }
          showNotification('success', data?.message);
          // showNotification('success', 'User successfully created!');
          // setIsDrawerOpen(false);
          handleDrawerClose();
          setRefetchUser(true);
        },
        onError: (err) => {
          const error = err as AxiosError;
          const data = error?.response?.data;
          const message =
            typeof data === 'object' && data !== null
              ? error?.response?.data?.message
              : error?.response?.data;
          if (data?.errors?.length > 0) {
            for (const erro of data?.errors) {
              notification.error({
                message: `Error on ${apps[erro.message]}`,
                description: erro.err,
                placement: 'bottomRight',
              });
              // showNotification('error', `Error on ${apps[erro.message]}`, erro.err);
            }
          } else {
            return showNotification('error', message || 'Something went wrong');
          }
        },
      });
    }
  };
  const handleRoleSelection = (
    value: Array<string>,
    resetField: UseFormResetField<Record<string, unknown>>
  ) => {
    if (!value.length || value.includes('1_hypr')) {
      resetField('location');
      resetField('companies');
      resetField('business_units');
    }
    if (hyperApp === 'hypr' && !value.length) {
      resetField('phone');
    }
    setData(setRoleData(data, value));
  };

  const handleAppSelect = (value: any) => {
    const isXpayExist = data?.filter((elem) => elem.key === 'Xpay-roles');
    if (value?.includes('xpay') && !isXpayExist.length) {
      setData((prev) => [
        ...prev,
        ...[
          {
            title: 'Xpay Roles',
            value: 'Xpay-roles',
            key: 'Xpay-roles',
            children: [
              {
                value: 'Admin_xPay',
                title: 'Admin',
                id: 'Admin_xPay',
              },
            ],
            disableCheckbox: true,
          },
        ],
      ]);
    }
    setApp(value);
  };
  const edit = async (rowItems: {
    rowData: any;
    role: string | null;
    brand_id: string;
    store_id: string;
  }) => {
    setBtnLoading(true);
    const { rowData, role, brand_id, store_id } = rowItems;
    setIsEdit(true);
    const isSD = role === 'sd';
    const isHypr = role === 'hypr';
    const isOE = role === 'oe';
    const isOR = role === 'or';
    const status = !parseInt(rowData.disabled);
    if (isSD) {
      setUserId(rowData.id);
      await refetchSdRoles();
      await refetchSdBrands();
      setSdEditData({ brand_id, store_id, user_id: rowData.id });
      setDefaultValues({
        company: [rowData.app],
        email: rowData.email,
        name: rowData.name,
        user_id: rowData.id,
        status,
      });
      await getSDUserInfo();

      setBtnLoading(false);
    }

    if (isOE) {
      setUserId(rowData.id);
      const roleId: Array<string> = rowData.oeRoles.map(
        (elem: { roleId: number; roleName: string }) => `${elem.roleId}_oe`
      );
      const locId: Array<string> = rowData.oeBrands.map(
        (elem: { locationId: number; locationName: string; brandId: number }) =>
          `${elem.locationId}_${elem.brandId}`
      );
      await refetchOeLocs();
      await refetchOeRoles();

      setDefaultValues(() => ({
        role: [...roleId],
        name: rowData.name,
        email: rowData.username,
        oeLocations: locId,
        user_id: rowData.id,
        status: Boolean(Number(rowData?.disabled)),
      }));
      setIsDrawerOpen(true);
    }

    if (isHypr) {
      setUserId(rowData.id);
      hyperApp = 'hypr';
      refetchCompanies();
      refetchLocations();
      refetchBusiness();
      await refetchHyprRoles();
      setDefaultValues({
        company: [rowData.app],
        email: rowData.username,
        name: rowData.name,
        role: [rowData.roleId + '_hypr'],
        location: rowData?.locations?.map((loc: { id: number; name: string }) => loc.id),
        companies: rowData?.companies?.map((company: { id: number; name: string }) => company.id),
        business_units: rowData?.business_units?.map(
          (business: { id: number; name: string }) => business.id
        ),
        user_id: rowData.id,
        status,
        cnic: rowData.cnic,
        phone: rowData.phone,
      });
      // setIsDrawerOpen(true);
      setBtnLoading(false);
    }
    if (isOR) {
      setUserId(rowData.id);
      await refetchORRoles();
      setDefaultValues({
        company: [rowData.app],
        ...(isOR ? { email: rowData.username } : { email: rowData.email }),
        name: rowData.name,
        user_id: rowData.id,
        role: [rowData.roleId],
        status,
      });

      setBtnLoading(false);
    }
    if (rowData?.isXpay) {
      setDefaultValues({
        company: ['xpay'],
        status,
        email: rowData.username,
        name: rowData.name,
        role: ['Admin_xPay'],
      });
      setIsDrawerOpen(true);
      setXpayStatus(rowData?.isXpay);
      setData(() => [
        ...[
          {
            title: 'Xpay Roles',
            value: 'Xpay-roles',
            key: 'Xpay-roles',
            children: [
              {
                value: 'Admin_xPay',
                title: 'Admin',
                id: 'Admin_xPay',
              },
            ],
            disableCheckbox: true,
          },
        ],
      ]);
    }
    setBtnLoading(false);
  };
  const saveParentRole = (roles: any) => {
    if (!roles) {
      setApp([]);
      setParentRoles([]);
      setSdBrands([]);
      setData([]);
      setDefaultValues({});
      setApp([]);
    } else {
      setParentRoles([roles] ?? []);
      setApp([roles]);
    }
    if (roles === 'xpay') {
      setData(() => [
        ...[
          {
            title: 'Xpay Roles',
            value: 'Xpay-roles',
            key: 'Xpay-roles',
            children: [
              {
                value: 'Admin_xPay',
                title: 'Admin',
                id: 'Admin_xPay',
              },
            ],
            disableCheckbox: true,
          },
        ],
      ]);
    }
  };
  return (
    <div className="user-management">
      <PageHeader
        label="Users Roles"
        buttons={[
          {
            title: 'Create New User',
            onClick: () => {
              setIsDrawerOpen(true);
              // setIsEdit(false);
              if (!isEdit) {
                setDefaultValues({});
              }
            },
          },
        ]}
      />
      {
        <AllUsers
          {...{
            appsData,
            edit,
            btnLoading,
            saveParentRole,
            refetchUser,
            userId: usersId,
          }}
        />
      }
      <FormDrawer
        title={isEdit ? 'Update User' : 'Create New User'}
        handleClose={handleDrawerClose}
        visible={isDrawerOpen}
        destroyOnClose={true}
      >
        <Form
          onSubmit={onSubmit}
          schema={schemas}
          options={{
            defaultValues: {
              ...defaultValues,
            },
          }}
        >
          {({ control, resetField, setValue, watch, clearErrors }) => {
            return (
              <>
                <SelectController
                  placeholder="Select App"
                  label="Select App"
                  // size="large"
                  name="company"
                  defaultValue={parentRole && parentRole?.length > 0 ? parentRole : []}
                  isMulti={isEdit ? false : parentRole?.length > 0 ? false : true}
                  options={appsData || []}
                  fieldNames={{ label: 'label', value: 'value' }}
                  isSearchable={false}
                  onChange={handleAppSelect}
                  onDeselect={(value: string) => {
                    setValue(
                      'role',
                      watch()?.role?.filter((elem) => elem.split('_')[1] !== value)
                    );
                    setData((prev) => {
                      return prev.filter(
                        (elem) => elem.key.split('-')[0].toLowerCase() !== value.toLowerCase()
                      );
                    });

                    clearErrors('role');
                  }}
                  onClear={() => {
                    setData([]);
                    resetField('role');
                    resetField('location');
                  }}
                  control={control}
                  disabled={isEdit || Boolean(parentRole?.length)}
                />
                {isAppExist(watch().company || (app && app.length > 0 ? app : [])) && (
                  <TreeSelectController
                    label="Select Roles"
                    name="role"
                    control={control}
                    placeholder="Select Roles"
                    treeData={data}
                    onChange={(value: any) => handleRoleSelection(value, resetField)}
                    disabled={defaultValues?.company?.includes('hypr') || xpayStatus}
                  />
                )}

                {isEdit ? (
                  <InputController disabled={isEdit} name="name" control={control} label="Name*" />
                ) : (
                  <>
                    <InputController name="firstName" control={control} label="First Name*" />
                    <InputController name="lastName" control={control} label="Last Name*" />
                  </>
                )}
                <InputController name="email" disabled={isEdit} control={control} label="Email*" />
                {(watch().company?.includes('hypr') || app?.includes('hypr')) && (
                  <HyprFields
                    {...{
                      watch,
                      state: hyperPermission,
                      control,
                      BusinessLocations,
                      totalCompanies,
                      totalBusinessUnits,
                      isEdit,
                    }}
                  />
                )}
                {(watch().company?.includes('sd') || app?.includes('sd')) && (
                  <SDFields {...{ control, data: sdBrands }} />
                )}
                {watch().company?.includes('oe') && (
                  <OEFields
                    {...{
                      control,
                      OELocations,
                      watch,
                      data,
                    }}
                  />
                )}
                {isEdit && <SwitchController {...{ control, name: 'status', label: 'Status' }} />}

                <div className="flex justify-between min-h-22 btns mt-5">
                  <Button
                    loading={
                      isEdit && xpayStatus
                        ? updateStatus.isLoading
                        : isEdit
                        ? updateMutation.isLoading
                        : createUserMutation.isLoading
                    }
                    type="primary"
                    htmlType="submit"
                  >
                    Submit
                  </Button>
                  <Button
                    className="hover:border-black hover:text-black hover:bg-white active:border-black active:text-black active:bg-white focus:border-black focus:text-black focus:bg-white"
                    title="Cancel"
                    onClick={handleDrawerClose}
                  >
                    Cancel
                  </Button>
                </div>
              </>
            );
          }}
        </Form>
      </FormDrawer>
    </div>
  );
};

export default UserManagement;
