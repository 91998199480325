import { z } from 'zod';

import { checkWhiteSpaces } from '../../auth/components/LoginForm';
import { hyprPhoneROles } from '../components/common/hyperFields';

import { hyperRoles } from './utils';

export const schema = (isEdit: boolean) => {
  return z
    .object({
      company: z
        .string({
          invalid_type_error: 'Invalid name',
          required_error: 'Name is required',
        })
        .array()
        .nonempty('App is required'),
      firstName: z
        .string({
          required_error: 'First Name is required',
          invalid_type_error: 'First Name is required',
        })
        .max(12, { message: 'Max 12 characters allowed.' })
        .regex(/^[a-zA-Z]+$/, { message: 'Only alphabets are allowed' })
        .optional(),
      name: z
        .string({
          required_error: 'Name is required',
          invalid_type_error: 'Name is required',
        })
        // .max(12, { message: 'Your Name must be between 12 characters long.' })
        .optional(),
      // .min(1, 'First Name is required'),
      lastName: z
        .string({
          required_error: 'Last Name is required',
          invalid_type_error: 'Last Name is required',
        })
        .max(12, { message: 'Max 12 characters allowed.' })
        .regex(/^[a-zA-Z]+$/, { message: 'Only alphabets are allowed' })
        .optional(),
      // .min(1, 'Last Name is required'),
      role: z.array(z.string()).optional(),
      sdBrands: z.array(z.string()).optional(),
      email: z
        .string({
          required_error: 'Email is required',
          invalid_type_error: 'Please enter valid email',
        })
        .email()
        .max(320, { message: 'Max 320 characters allowed.' }),
      status: z.boolean().optional(),
      location: z.array(z.number().or(z.string())).optional(),
      companies: z.array(z.number().or(z.string())).optional(),
      phone: z
        .string()
        // .regex(/^\d+$/, { message: 'Enter valid phone number' })
        // .min(12, { message: 'Enter valid phone number' })

        .max(12, { message: 'Max 12 characters allowed' })
        .optional(),
      cnic: z
        .string()
        // .regex(/^\d+$/, { message: 'Enter valid CNIC number' })
        // .min(13, { message: 'Max 13 characters allowed' })
        .max(14, { message: 'Max 14 characters allowed' })
        .optional(),
      // phone: z.preprocess(
      //   (a) => parseInt(a as string, 10),
      //   z.number().positive().max(12).optional()
      // ),
      // companies: z.optional(z.number().or(z.string())),
      business_units: z.array(z.number().or(z.string())).optional(),
      // oeBrands: z.array(z.number().or(z.string())).optional(),
      oeLocations: z.array(z.string()).optional(),
    })
    .superRefine((val, ctx) => {
      const isHyper = val?.company?.includes('hypr');
      const isSD = val?.company?.includes('sd');
      const isOr = val?.company?.includes('or');
      const isOe = val?.company?.includes('oe');
      const isXPay = val?.company?.includes('xpay');

      // const isSdBrnadExist = checkIsExists(val.sdBrands, '_brand');
      // const isSdStoreExist = checkIsExists(val.sdBrands, '_store');
      let sdRole = null;
      if (val?.role?.includes('Super User') && isSD) {
        sdRole = val?.role;
      } else {
        sdRole = checkIsExists(val?.role, '_sd');
      }
      const orRole = checkIsExists(val?.role, '_or');
      const hyprRole = checkIsExists(val?.role, '_hypr');
      const oeRole = checkIsExists(val?.role, '_oe');
      const xPayRole = checkIsExists(val?.role, 'xPay');

      // const id = val?.role
      //   ?.filter((elem: string) => elem.includes('_oe'))
      //   .some((role: string) => role.split('_')[0] === '2');
      // const isAdmin = val?.role
      //   ?.filter((elem: string) => elem.includes('_oe'))
      //   .some((role: string) => role.split('_')[0] === '1');
      // const isOEAdmin = !isAdmin && !id;
      if (isHyper) {
        const checkNumbers = new RegExp('^[0-9]+$');
        if (val.phone) {
          checkWhiteSpaces(val.phone ?? '', 'phone', ctx);
          const isNumber = checkNumbers.test(val.phone);
          if (!isNumber) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['phone'],
              message: 'Enter Valid Phone Number',
            });
          }
          if (val.phone.length < 10) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['phone'],
              message: 'Minimum 10 characters required',
            });
          }
        }
        if (val.cnic) {
          checkWhiteSpaces(val.cnic ?? '', 'cnic', ctx);
          const isNumber = checkNumbers.test(val.cnic);
          if (!isNumber) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['cnic'],
              message: 'Enter Valid CNIC Number',
            });
          }
          if (val.cnic?.length < 13) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              path: ['cnic'],
              message: 'Minimum 13 characters required',
            });
          }
        }
        if (!hyprRole?.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['role'],
            message: 'Hypr Role is required',
          });
        }
        if (
          hyperRoles('1_hypr', val, false, true) &&
          hyperRoles('9_hypr', val, false, true) &&
          hyperRoles('15_hypr', val, false, true) &&
          !val?.location?.length
        ) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['location'],
            message: 'Location is required',
          });
        }
        const value = val?.role?.filter((elem) => elem.includes('hypr')).toString();
        if (hyprPhoneROles.includes(value ?? 'false')) {
          if (!val.phone) {
            {
              ctx.addIssue({
                code: z.ZodIssueCode.custom,
                path: ['phone'],
                message: 'Phone is required',
              });
            }
          }
        }
        if (val.role?.[0] === '9_hypr' && !val?.companies?.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['companies'],
            message: 'Company is required',
          });
        }
        if (val.role?.[0] === '15_hypr' && !val?.business_units?.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['business_units'],
            message: 'Business units is required',
          });
        }
      }
      if (isOe) {
        const loc = Array.isArray(val?.oeLocations)
          ? val?.oeLocations?.length === 0
          : !val.oeLocations;
        // const brand = Array.isArray(val?.oeBrands) ? val?.oeBrands?.length === 0 : !val.oeBrands;
        if (!oeRole?.length) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['role'],
            message: 'OE Role is required',
          });
        }
        if (loc) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['oeLocations'],
            message: 'Location is required',
          });
        }
        // if (brand) {
        //   ctx.addIssue({
        //     code: z.ZodIssueCode.custom,
        //     path: ['oeBrands'],
        //     message: 'OEBrand is required',
        //   });
        // }
      }
      if (!val.company || val?.company?.length < 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['company'],
          message: 'App is required',
        });
      }

      if ((!val.role && val.company.length) || (!val.role?.length && val.company.length)) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['role'],
          message: 'Role is required',
        });
      }

      if (isXPay && !xPayRole?.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['role'],
          message: 'Xpay Role is required',
        });
      }
      if (isSD && !sdRole?.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['role'],
          message: 'SD Role is required',
        });
      }
      if (isOr && !orRole?.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['role'],
          message: 'OR Role is required',
        });
      }

      if (isSD && !val.sdBrands?.length) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['sdBrands'],
          message: 'Brand and Store is required',
        });
      }
      if (isEdit) {
        if (!val.name) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['name'],
            message: 'Name is required',
          });
        }
      }
      if (!isEdit) {
        if (val?.firstName?.trim().length === 0 || !val.firstName) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['firstName'],
            message: 'First Name is required',
          });
        }
        if (val?.lastName?.trim().length === 0 || !val.lastName) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            path: ['lastName'],
            message: 'Last Name is required',
          });
        }
      }

      // if (isSD && !isSdBrnadExist?.length) {
      //   ctx.addIssue({
      //     code: z.ZodIssueCode.custom,
      //     path: ['sdBrands'],
      //     message: 'Brand is required',
      //   });
      // }
      // if (isSD && !isSdStoreExist?.length) {
      //   ctx.addIssue({
      //     code: z.ZodIssueCode.custom,
      //     path: ['sdBrands'],
      //     message: 'Store is required',
      //   });
      // }
    });
};
const checkIsExists = (data: Array<string> | undefined, app: string) =>
  data?.filter((elem) => elem.includes(app));
