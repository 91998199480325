import { notification } from 'antd';
import { AxiosError } from 'axios';
import { useQuery } from 'react-query';

import { brandID, sdData, sdRolesRes, sdStores, TreeDataType } from '../../constants';
import { fetchSDUserInfo, getAllSdStores, getSDRoles, getSDUsers } from '../index';

// sd Roles

export const useGetSDRoles = (sdApp: string | boolean, onSuccess: (args: TreeDataType) => void) => {
  return useQuery<sdRolesRes, Error, sdRolesRes>(['sd-roles'], ({ signal }) => getSDRoles(signal), {
    onSuccess: ({ data }) => {
      const sd =
        data.data && data?.data?.length > 0
          ? data?.data?.map((elem: any) => ({
              value: elem.id + '_sd',
              title: elem.name,
              id: elem.id,
            }))
          : [];
      const ids = sd?.map((o) => o.value);
      const filtered = sd?.filter(({ value }, index) => !ids.includes(value, index + 1));
      const sdRoles = [
        {
          title: 'SD Roles',
          value: 'SD-roles',
          key: 'SD-roles',
          children: filtered,
          disableCheckbox: true,
        },
      ];
      onSuccess(sdRoles);
    },
    onError: (response) => {
      notification.error({ message: response?.message || 'Something went wrong.' });
    },
    enabled: !!sdApp,
  });
};

export const useGetSDBrandStores = (
  sdRole: string | boolean,
  onSuccess: (stores: Array<sdStores[]>, brands: brandID[]) => void
) => {
  return useQuery(['sd-stores', sdRole], getAllSdStores, {
    onSuccess: (res) => {
      const stores: Array<sdStores[]> = [];
      const brands: brandID[] = [];
      res?.data.forEach((elem) => {
        brands.push({
          label: elem.brand_name,
          value: elem.brand_id,
        });

        stores.push(elem.stores);
      });
      console.log(stores, brands);
      onSuccess(stores, brands);
    },
    enabled: !!sdRole,
  });
};

export const useGetSDUsers = (sdData: SdData, onSuccess: (args: sdUsers['data']) => void) => {
  const { store_id } = sdData;
  // const { data: xapUsers } = useFetchXapUsers();
  // console.log(xapUsers);

  return useQuery<sdUsers>(['sd-Users', store_id], () => getSDUsers(sdData), {
    enabled: !!store_id,
    onSuccess: (res) => {
      onSuccess(res?.data);
    },
  });
};

export type SdData = {
  brand_id: string | string[];
  store_id: string;
  pageSize: number;
  page: number;
};
type sdUsers = {
  data: {
    page: {
      pageNumber: number;
      numberOfElements: number;
      totalElements: number;
      totalPages: number;
    };
    data: {
      id: string;
      name: string;
      email: string;
      phone: string;
      is_super: string;
      user_role_name: string;
      status: string;
    }[];
  };
};

export const useGetSDUserInfo = (
  args: Partial<sdData>,
  onSuccess: (args: userInfo['data']) => void
) => {
  const { store_id, brand_id } = args;
  return useQuery<userInfo>(['sd-Users-info', store_id, brand_id], () => fetchSDUserInfo(args), {
    enabled: !!store_id && !!brand_id,
    onSuccess: (res) => {
      onSuccess(res?.data);
    },
    onError: (err) => {
      const error = err as AxiosError;
      if (error?.response?.status === (400 || 401 || 403)) {
        return notification.error({
          message: `you don't have permission to edit this user. Please contact SDSupport`,
        });
      }
    },
  });
};

type userInfo = {
  data: {
    brand_info: {
      id: string;
      name: string;
      key: string;
      stores: {
        id: string;
        name: string;
        location: string;
        brand_id: string;
      }[];
    }[];
    user_info: {
      id: string;
      name: string;
      email: string;
      phone: string;
      is_super: string;
      user_role_id: string;
      status: string;
    };
  }[];
};
