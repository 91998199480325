import { Select, SelectProps } from 'antd';
import clsx from 'clsx';
import { debounce } from 'lodash';
import React, { useCallback, useEffect, useState, forwardRef } from 'react';

import { FieldWrapper, FieldWrapperPassThroughProps } from '../FieldWrapper';
import './assets/styles.scss';
export type Option = {
  label: string;
  value: string | number;
  disabled?: boolean;
};

export type SelectFieldProps = FieldWrapperPassThroughProps & {
  options: Option[];
  className?: string;
  defaultValue?: string;
  placeholder?: string;
  disabled?: boolean;
  isSearchable?: boolean;
  isMulti?: boolean;
  // value?: string | string[] | null;
  isAsync?: boolean;
  loadOptions?: (x: string) => Option[];
  handleChange?: (x: string[]) => void;
  isClearable?: boolean;
} & SelectProps;

const { Option } = Select;
type Ref = any;
export const SelectField = forwardRef<Ref, SelectFieldProps>(
  (
    {
      label,
      options,
      error,
      className,
      defaultValue,
      placeholder = 'Select',
      disabled,
      isSearchable,
      isMulti,
      isAsync,
      loadOptions,
      value,
      handleChange,
      isClearable = true,
      size,
      fieldNames,
      ...rest
    },
    ref
  ) => {
    const [userOptions, setOptions] = useState<Option[]>(options);
    const [fetching, setFetching] = useState<boolean>(false);
    const mode = isMulti ? 'multiple' : undefined;

    const handleSearch = (value: string) => {
      if (isAsync && loadOptions) {
        const cb = (options: Option[]) => {
          if (options?.length) {
            setOptions(options);
          }
          setFetching(false);
        };
        handleLoadOptions(value, cb);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
    const handleLoadOptions = useCallback(
      debounce(async (value, cb) => {
        if (loadOptions) {
          setFetching(true);
          setOptions([]);
          const options = await loadOptions(value);
          cb(options);
        }
      }, 1000),
      []
    );
    const handleFilterOptions = (input: string, option: undefined | Option) => {
      if (option) {
        if (option?.label) {
          return option?.label.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            ? true
            : false;
        } else if (option?.value) {
          return option?.value.toString().toLowerCase().indexOf(input.toLowerCase()) >= 0
            ? true
            : false;
        }
      } else return false;
    };
    // return true;

    useEffect(() => {
      setOptions(options);
    }, [options]);
    return (
      <FieldWrapper label={label} error={error}>
        <Select
          allowClear={isClearable}
          mode={mode}
          placeholder={placeholder}
          className={clsx(`select-field ${error && 'ant-select-error'}`, className)}
          value={value === '' ? null : value}
          defaultValue={defaultValue}
          options={userOptions}
          onChange={handleChange}
          onSearch={isSearchable ? (value) => handleSearch(value) : undefined}
          disabled={disabled}
          showSearch={isSearchable}
          size={size}
          filterOption={isAsync ? false : handleFilterOptions}
          notFoundContent={fetching ? 'Loading...' : 'No Data Found'}
          fieldNames={fieldNames ? fieldNames : { label: 'name', value: 'id' }}
          dropdownMatchSelectWidth={false}
          // style={{ minWidth: '20vw' }}
          ref={ref}
          {...rest}
          style={{ width: 200 }}
          // width="100px"
        />
      </FieldWrapper>
    );
  }
);

SelectField.displayName = 'SelectField';
