import { axiosClient, Urls } from '@/lib/axios';

export const forgetPassword = async (payload: { email: string }) => {
  let response;
  try {
    response = await axiosClient.post(Urls.POST.forgetPassword, payload);
    return {
      error: false,
      data: response,
      message: response.data,
      code: response.status,
    };
  } catch (e: any) {
    response = {
      error: true,
      data:
        (e.response && e.response.data.errorMessage) ||
        e.response.data ||
        e.response.message ||
        'Something went wrong.',
      message: e.response.data.message,
      code: e.response.status || 404,
    };
  }
  return response;
};
