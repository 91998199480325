import Axios, { AxiosRequestConfig, AxiosError } from 'axios';

import { API_URL, OMNIRETAIN_URL, HYPR_URL, OMNICHANNEL_ENGINE_URL, SHOPDESK_URL } from '@/config';
import storage from '@/utils/storage';

import { handleRefreshToken } from './newRefreshToke';
export type axiosError = AxiosError;
export const hyperBaseURL = HYPR_URL;
export const sdBaseURL = `${SHOPDESK_URL}/api`;

export const orBaseURl = OMNIRETAIN_URL;
export const oeBaseURL = `${OMNICHANNEL_ENGINE_URL}/oe`;

const token = storage.getToken();
function authRequestInterceptor(config: AxiosRequestConfig) {
  config.headers.Accept = 'application/json';
  return config;
}
console.log(token);

export const axiosClient = Axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

export const hyperConfig = {
  baseURL: hyperBaseURL,
  headers: {
    clientid: 'unifiedPortal',
  },
};

export const sdConfig: AxiosRequestConfig = {
  baseURL: sdBaseURL,
  // ...headers,
};
export const orConfig: AxiosRequestConfig = {
  baseURL: orBaseURl,
  // ...headers,
};
export const oeConfig = {
  baseURL: oeBaseURL,
};

export const oeConfig1 = {
  baseURL: 'https://api-oe-biz-dev.xstak.com/configs/api/v1/',
};

export const Urls = {
  POST: {
    signIn: '/v1/user/login',
    forgetPassword: `/v1/user/password/forgot`,
    refreshToken: '/v1/token/refresh',
    xapNewUser: '/v1/user/create',
    xapUserUpdate: '/v1/user/role/add',
  },
  GET: {
    getXapUsers: 'v1/users/realms/',
    userRoles: '/v1/user/roles',
    hyperRoles: '/roles/getAllRoles?clientTimeOffset=-300',
    hyperCompany: '/company/getAllCompanies?clientTimeOffset=-300',
    hyperUserInfo: '/auth/userInfo',
    // hyperBussinessUnit: `/businessUnit?company_id=${companyId}&clientTimeOffset=-300`
    sdRoles: '/user_roles?limit=100&page=1',
    sdStores: '/company/stores/get_all_stores',
    sdUser: '/users',
    sdUserInfo: '/users?id=',
    getORPermissions: '/backend/core/' + 'userpermissions/',
    getOERoles: '/configs/roles',
    getOEBrands: '/configs/b/locations',
  },
  PUT: {
    updateStatus: '/v1/user/status',
  },
  DELETE: {},
  PATCH: {
    resetPassword: '/v1/user/password/reset',
  },
};

axiosClient.interceptors.request.use(authRequestInterceptor);
// axiosClient.defaults.headers.common['Authorization'] = storage.getToken();
axiosClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
  (error) => {
    const originalRequest = error.config;
    const isAppExpired: boolean = error?.response?.data?.errors?.some(
      (elem: { code: number; message: string }) => elem?.code === 401
    );
    const statusCode = error.response?.status === 401;
    const isUnAuthorize = isAppExpired || statusCode;
    const refreshToken = storage.getRefreshToken();
    const token = storage.getToken();
    const realm = storage.getRealm();
    const isExist = isUnAuthorize && !originalRequest._retry && refreshToken && token && realm;
    if (isExist) {
      originalRequest._retry = true;
      return handleRefreshToken(originalRequest, axiosClient);
    } else {
      return Promise.reject(error || 'Something went wrong.');
    }
  }
);
