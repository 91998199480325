import { notification } from 'antd';

export const showNotification = (
  type: 'error' | 'warning' | 'success',
  message: string,
  description?: string
) => {
  const config = {
    message,
    description,
    duration: 5,
    key: type,
    placement: 'bottomRight' as const,
    maxCount: 1,
  };
  notification.close(type);
  return notification[type](config);
};
