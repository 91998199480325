import { IdcardOutlined, MailOutlined } from '@ant-design/icons';
import { Button, notification } from 'antd';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Form, InputField } from '@/components/Form';
import { forgetPassword } from '@/features/auth';
import { showNotification } from '@/utils/scripts';

// const schema = z.object({
//   email: z.string().min(1, 'ERequired'),
//   accountId: z.string().min(1, 'Required'),
// });
const schema = z.object({
  email: z
    .string({
      invalid_type_error: 'Please enter valid email',
    })
    .min(1, 'Email is required')
    .email(),
  accountId: z.string().min(1, 'Account ID is required'),
});
type ForgetEmailValues = {
  email: string;
  accountId: string;
};
export const ForgotPasswordForm = () => {
  const [loading, setLoading] = useState(false);
  return (
    <div className="p-6 bg-white rounded-xl lg:w-[32vw] mx-6">
      <div className="login_submit flex-col mb-[17px] md:mb-[1.15vw]">
        <Link className="flex text-white pb-3" to="/">
          <img
            src={
              'https://uploads-ssl.webflow.com/6225e3bcdcd38d2a25b1df9e/62f4f1d8b5f15173d11b5d40_logo-xstak.svg'
            }
            alt="xStak"
          />
        </Link>

        <p className=" text-2xl text-[#262626] md:text-3xl text-center font-bold md:font-semibold mb-0">
          Forgot Password
        </p>
        <p className="text-base text-center max-lg:max-w-[400px] mt-[16px] mb-0 md:text-[0.87vw] text-gray-8 md:mt-[1vw]">
          Enter the email associated with your account and we will send an email with instructions
          to reset your password.
        </p>
      </div>

      <Form<ForgetEmailValues, typeof schema>
        onSubmit={async (values) => {
          setLoading(true);
          const payload = {
            email: values.email,
            realm: values.accountId,
          };
          const response = await forgetPassword(payload);
          // setEmailEntered(true);
          if (response.error === false) {
            setLoading(false);
            notification.success({
              message: 'Success',
              description: 'Please check your inbox!',
              duration: 2,
              placement: 'bottomRight',
            });
          } else {
            setLoading(false);
            showNotification('error', response.message || response.data);
          }
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <div className="relative">
              <InputField
                type="text"
                label="Account ID"
                suffix={<IdcardOutlined className="opacity-50" />}
                placeholder="125abdf"
                error={formState.errors['accountId']}
                registration={register('accountId')}
              />
            </div>
            <div className="relative">
              <InputField
                type="email"
                label="Email Address"
                placeholder="abc@xyz.com"
                suffix={<MailOutlined className="input_icon" />}
                error={formState.errors['email']}
                registration={register('email')}
              />
            </div>
            <div>
              <Button
                htmlType="submit"
                className="bg-black mt-5 hover:bg-black hover:border-black border-black active:border-black active:bg-black active:text-white focus:border-black focus:bg-black focus:text-white text-white hover:text-white w-full rounded h-[40px] text-[16px] md:text-[1vw] flex items-center  justify-center"
                loading={loading}
              >
                Submit
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};
