import { AxiosInstance, AxiosRequestConfig } from 'axios';

import { showNotification } from '@/utils/scripts';
import storage from '@/utils/storage';

import { axiosError, Urls } from './axios';

let isRefreshing = false;

const handleIsRefreshing = (value: boolean) => (isRefreshing = value);

let newRefreshToken: string | null = null;

export const handleRefreshToken = async (
  originalRequest: AxiosRequestConfig,
  axiosInstance: AxiosInstance
) => {
  const refreshToken = storage.getRefreshToken();
  const realm = storage.getRealm();
  if (refreshToken && realm) {
    if (!isRefreshing) {
      try {
        const url = Urls.POST.refreshToken;
        handleIsRefreshing(true);
        const data: User = await axiosInstance.post(url, {
          refreshToken,
          realm,
        });
        if (data.login) {
          const accessToken = data?.access_token;
          const refreshToken = data?.refresh_token;
          newRefreshToken = refreshToken;
          storage.setToken(accessToken);
          storage.setRefreshToken(refreshToken);
          // window.localStorage.setItem('name', JSON.stringify(data?.name ?? 'Admin'));
          // storage.setRealm(data?.realm);
          // storage.setApps(data?.apps);
          handleIsRefreshing(false);
        }
      } catch (err) {
        const error = err as axiosError;
        console.log(error);
        handleIsRefreshing(false);
        newRefreshToken = null;
        showNotification('error', 'Your session has expired. You need to log in again!');

        setTimeout(() => {
          window.localStorage.clear();
          window.location.assign('/auth/login/');
        }, 2000);
      }
      const retryOriginalRequest = new Promise((resolve) => {
        if (newRefreshToken) {
          originalRequest.headers.Authorization = newRefreshToken;
          resolve(axiosInstance(originalRequest));
        }
      });
      return retryOriginalRequest;
    }
  } else {
    newRefreshToken = null;
    window.localStorage.clear();
    window.location.assign('/auth/login/');
  }
};
type User = {
  login: boolean;
  userName: string;
  name: string;
  email: string;
  apps: Array<string>;
  access_token: string;
  expires_in: number;
  refresh_expires_in: number;
  refresh_token: string;
  token_type: string;
  'not-before-policy': number;
  session_state: string;
  scope: string;
  realm: string;
};
