import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';

import { InputField, InputFieldProps } from '../inputField/index';

type FormControllerProps<FormValues> = {
  name: Path<FormValues>;
  defaultValue?: any;
  control: Control<FormValues>;
  rules?: RegisterOptions;
  fieldType?: 'input' | 'password';
  type?: string;
  parentClassName?: string;
} & Omit<InputFieldProps, 'value'>;

export const InputController = <FormValues extends FieldValues>({
  name,
  control,
  rules,
  defaultValue,
  fieldType = 'input',
  label,
  type,
  parentClassName,
  ...props
}: FormControllerProps<FormValues>) => {
  const componets = {
    input: InputField,
  };
  const InputComponent = componets[fieldType];
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <InputComponent
            label={label}
            value={field?.value}
            type={type}
            onChange={field.onChange}
            error={error?.message}
            parentClassName={parentClassName}
            ref={field.ref}
            {...props}
          />
        );
      }}
    />
  );
};
