import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';

import { Switch, SwitchButtonProps } from '../Switch';

type FormControllerProps<FormValues> = {
  name: Path<FormValues>;
  defaultValue?: any;
  control: Control<FormValues>;
  rules?: RegisterOptions;
  onChange?: (value: boolean) => void;
} & Omit<SwitchButtonProps, ''>;

export const SwitchController = <FormValues extends FieldValues>({
  name,
  control,
  rules,
  defaultValue,
  label,
  onChange,
  parentClassName,
  ...props
}: FormControllerProps<FormValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <Switch
            label={label}
            checked={field.value}
            error={error?.message}
            parentClassName={parentClassName}
            onChange={(value) => {
              if (onChange) {
                onChange(value);
              }
              field.onChange(value);
            }}
            {...props}
          />
        );
      }}
    />
  );
};
