import { notification } from 'antd';
import { useQuery } from 'react-query';

import storage from '@/utils/storage';

import { treeChildren, TreeDataType } from '../../constants';
import { getOEBrands, getOEBrandsLocations, getOeRoles, getOEusers } from '../index';

export const useGetOeRoles = (oeApp: string | boolean, onSuccess: (args: TreeDataType) => void) => {
  return useQuery<oeRoles, Error, oeRoles>(['oe-roles', oeApp], getOeRoles, {
    onSuccess: (res) => {
      const children = res.data.length
        ? res.data
            .map((elem) => ({
              value: elem.id + '_oe',
              title: elem.role,
              id: elem.id,
            }))
            .filter((elem) => elem.id !== 1)
        : [];
      const oeRoles = [
        {
          title: 'Oe Roles',
          value: 'oe-roles',
          key: 'oe-roles',
          disableCheckbox: true,
          children,
        },
      ];
      onSuccess(oeRoles);
    },
    onError: (response: any) => {
      notification.error({
        message: response?.message || 'Something went wrong while fetching oe Roles.',
      });
    },
    enabled: !!oeApp,
  });
};

export const useGetOeBrands = (oeApp: string | boolean, onSuccess: (args: brand[]) => void) => {
  return useQuery<oeBrands>(['oe-brands', oeApp], getOEBrands, {
    onSuccess: (res) => {
      const realm = storage.getRealm();
      const shortBrands = res?.data?.filter((elem) => elem.shortKey === realm);
      console.log(shortBrands);
      onSuccess(res.data);
    },
    onError: (response: any) => {
      notification.error({
        message: response?.response?.data?.message || response?.message || 'Something went wrong.',
      });
    },
    enabled: !!oeApp,
  });
};

export const useGetOeUsers = (oeApp: string | boolean, onSuccess: (args: oeUserRes) => void) => {
  return useQuery<oeUser, Error>(['oe-users', oeApp], getOEusers, {
    onSuccess: (res) => {
      const oeRoles_Brands: oeUserRes = res?.data
        ?.map((elem) => {
          const oeRoles = JSON.parse(elem?.assignedRoles);
          const oeBrands = JSON.parse(elem?.assignedLocations);
          const oeUserInfo = JSON.parse(elem?.basicInfo);
          return {
            oeRoles,
            oeBrands,
            ...oeUserInfo,
          };
        })
        .map((elem) => {
          return {
            ...elem,
            username: elem?.email,
          };
        });
      onSuccess(oeRoles_Brands);
    },
    onError: (response) => {
      notification.error({ message: response?.message || 'Something went wrong.' });
    },
    enabled: !!oeApp,
  });
};

export const useGetOEBrandLocations = (
  brand_id: Array<number | undefined> | undefined,
  role: any,
  oeBrands: brand[],
  onSuccess: (response: TreeDataType) => void
) => {
  const id = brand_id && brand_id?.length > 0 ? brand_id[brand_id.length - 1] : undefined;
  const name = oeBrands && oeBrands.filter((elem) => elem.id === id);
  useQuery<oeBrandLocations>(['oe-locations', id], () => getOEBrandsLocations(id), {
    onSuccess: (res) => {
      const finalPayload: TreeDataType = [];
      const children: treeChildren = [];
      if (res.data.length > 0) {
        res.data?.forEach((item) => {
          children.push({
            value: item.id + '_locs',
            title: item.name,
            id: item.id,
          });
        });
        finalPayload.push({
          value: id + '_loc',
          title: name[0]['name'],
          key: id + '_loc',
          children,
        });
      }
      onSuccess(finalPayload);
    },
    onError: (response: any) => {
      notification.error({
        message: response?.message || 'Something went wrong while fetching OE Brands.',
      });
    },
    enabled: !!id,
  });
};

export type oeRoles = {
  data: {
    id: number;
    role: string;
  }[];
};
export type brand = {
  id: number;
  name: string;
  enabled: boolean;
  shortKey: string;
  brandId: number;
};
export type oeBrands = {
  data: brand[];
};
export type oeBrandsReturnRes = {
  label: string;
  value: number;
  id: number;
};

export type oeBrandLocations = {
  data: {
    brandId: number;
    id: number;
    name: string;
  }[];
};
export type oeUser = {
  data: {
    basicInfo: any;
    assignedBrandId: any;
    assignedLocations: any;
    assignedRoles: any;
  }[];
};
// role.length > 0 ? (role?.filter(elem => elem.includes('2_oe')) ? false : true) : false

type oeUserRes = {
  basicInfo: {
    id: number;
    email: string;
    name: string;
    enabled: boolean;
    phone: string;
  };
  assignedBrandId: number;
  assignedLocations: {
    locationId: number;
    locationName: string;
    brandId: number;
  }[];
  assignedRoles: {
    roleId: number;
    roleName: string;
  }[];
}[];
