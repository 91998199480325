import { Table, Skeleton } from 'antd';

import { ColumnData } from '../types';
export type SkeletonTableProps = {
  columns: ColumnData[];
  rowCount: number;
};
export const SkeletonTable = ({ columns, rowCount = 10 }: SkeletonTableProps) => {
  const renderColumns = (columns: ColumnData[]) => {
    return columns.map((column) => {
      return {
        ...column,
        render: () => {
          return <Skeleton active paragraph={false} />;
        },
      };
    });
  };
  return (
    <Table
      rowKey="key"
      pagination={false}
      dataSource={[...Array(rowCount)].map((_, index) => ({
        key: `key${index}`,
      }))}
      columns={renderColumns(columns)}
    />
  );
};

export default SkeletonTable;
