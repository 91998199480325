import React from 'react';

import { InputController, SelectController } from '@/components/Form';

import { checkPermissions, hyperRoles } from '../../constants';

function HyprFields({
  watch,
  state,
  control,
  BusinessLocations,
  totalCompanies,
  totalBusinessUnits,
}: {
  watch: any;
  control: any;
  state: Array<string | number>;
  BusinessLocations: any;
  totalCompanies: any;
  totalBusinessUnits: any;
}) {
  return (
    <>
      <InputController name="phone" control={control} label="Phone Number*" />
      <InputController name="cnic" control={control} label="CNIC Number" />

      {hyperRoles('1_hypr', watch) &&
        hyperRoles('9_hypr', watch) &&
        hyperRoles('15_hypr', watch) &&
        watch()?.role?.some((elem: string) => elem?.split('_')[1] === 'hypr') &&
        checkPermissions(['*', 'L_ALL_LOC'], state) && (
          <SelectController
            className="select_cell_height"
            name="location"
            control={control}
            options={BusinessLocations || []}
            // defaultValue={defaultLocations || []}
            label="Select Cell"
            isMulti
          />
        )}
      {hyperRoles('9_hypr', watch, true) && checkPermissions(['*', 'L_ALL_COMP'], state) && (
        <SelectController
          className="select_cell_height"
          name="companies"
          control={control}
          options={totalCompanies || []}
          // defaultValue={defaultCompanies || []}
          label="Select Company"
          isMulti
        />
      )}
      {hyperRoles('15_hypr', watch, true) && checkPermissions(['*', 'L_BU'], state) && (
        <SelectController
          name="business_units"
          control={control}
          options={totalBusinessUnits || []}
          // defaultValue={defaultBusinessUnits || []}
          label="Select Business Units"
          isMulti
        />
      )}
    </>
  );
}

export default HyprFields;

export const hyprPhoneROles = [
  '5_hypr',
  '6_hypr',
  '7_hypr',
  '8_hypr',
  '14_hypr',
  '16_hypr',
  '4_hypr',
];
