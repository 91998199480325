import { initReactQueryAuth } from 'react-query-auth';

import { Spinner } from '@/components/Elements';
// import // loginWithEmailAndPassword,
//   // getUser,
//   // registerWithEmailAndPassword,
//   // forgetPassword,
// UserResponse,
//   // LoginCredentialsDTO,
//   // RegisterCredentialsDTO,
//   // AuthUser,
//   '@/features/auth';
// import { loginWithEmailAndPassword } from '@/features/auth';
import storage from '@/utils/storage';

// const handleUserResponse = async (data: UserResponse) => {
//   console.log(data.data);
//   if (data.error === false) {
//     const user = data.data.access_token;
//     storage.setToken(user);
//     return data;
//   } else {
//     console.log('erro');
//     return data;
//   }
// };

const loginFn = async (user: any) => {
  // const response = await loginWithEmailAndPassword(payload);
  // const user = await handleUserResponse(response);

  return user;
};

// const forgetPasswordFn = async (data: string) => {
//   const payload = {
//     realm: 'xstack1',
//     email: data,
//   };
//   const response = await forgetPassword(payload);
//   return response;
// };

const loadUser = async () => {
  // if (storage.getToken()) {
  //   return storage.getToken;
  // }
  return null;
};

// async function registerFn(data: RegisterCredentialsDTO) {
//   const response = await registerWithEmailAndPassword(data);
//   const user = await handleUserResponse(response);
//   return user;
// }

async function logoutFn() {
  storage.clearToken();
  window.localStorage.removeItem('auth_token');
  window.localStorage.removeItem('user-copilot');
  window.location.assign(window.location.origin as unknown as string);
}

const authConfig = {
  // registerFn,
  // forgetPasswordFn,
  loginFn,
  loadUser,
  logoutFn,
  LoaderComponent() {
    return (
      <div className="w-screen h-screen flex justify-center items-center">
        <Spinner size="xl" />
      </div>
    );
  },
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const { AuthProvider, useAuth } = initReactQueryAuth(authConfig);
