import { notification } from 'antd';
import { useQuery } from 'react-query';

import { getOrPermission, getOrRoles } from '../index';
type roles = {
  id: number;
  name: string;
};

export interface rolesData {
  data: {
    AuthGroup: roles[];
  };
}
export type returnRes = {
  AuthGroup: roles[];
};

export type orRolesTypes = {
  codename: string;
  content_type: number;
  id: number;
  name: string;
};
const GET_ROLES = `
query demo ($request:[AuthGroupRequest]){
  AuthGroup(request:$request){
    id
    name
  }
}`;

const GET_USERS = `
query demo ($request:[UserModelRequest], $limit: Int, $offset: Int,$sort_field:[String]){
    PaginatedUser(request:$request, limit:$limit, offset:$offset, sort_field: $sort_field){
        data{
            id
            username
        email
        phone
        complete_name
        is_active
        country_code
        group{name,id}
        }
        limit
        offset
        count
    }
}`;

const orUserDetails = `
query BaseConfiguration ($request: [BaseConfigurationModelRequest],$sort_field:[String]) {
    BaseConfiguration (request: $request, sort_field: $sort_field) {
        id
        company
    }
} `;

export const useGetOrCompany = (orApp: string | boolean) => {
  return useQuery<orCompany>(
    ['or-company', orApp],
    () =>
      getOrRoles({
        query: orUserDetails,
        variables: { request: [{}] },
      }),
    {
      onError: (response: any) => {
        notification.error({ message: response?.message || 'Something went wrong.' });
      },
      enabled: !!orApp,
    }
  );
};

export const useGetorUsers = (orVariables: any, onSuccess: (response: orUsers) => void) => {
  const { queryVariable, limit, pageNumber, orApp } = orVariables;
  console.log(queryVariable);

  return useQuery<orUsers>(
    ['or-users', orVariables],
    () =>
      getOrRoles({
        query: GET_USERS,
        variables: {
          // request: [{ company: queryVariable }],
          request: [{}],
          limit: limit,
          offset: pageNumber * limit,
          sort_field: '-id',
        },
      }),

    {
      onSuccess: (response) => {
        onSuccess(response);
      },
      onError: (response: any) => {
        notification.error({
          message: response?.message || 'Something went wrong while fetching OR Users',
        });
      },
      // enabled: !!orApp && !!queryVariable,
      enabled: !!orApp,
    }
  );
};

export const useGetorRoles = (
  orApp: string | boolean,
  // orPermission: orRolesTypes[],
  onSuccess: (response: returnRes) => void
) => {
  return useQuery<rolesData>(
    ['or-roles', orApp],
    () =>
      getOrRoles({
        query: GET_ROLES,
        variables: { request: [{}] },
      }),
    {
      onSuccess: (response) => {
        const { data } = response;
        onSuccess(data);
      },
      onError: (response: any) => {
        notification.error({
          message: response?.message || 'Something went wrong while fetching OR Roles',
        });
      },
      enabled: !!orApp,
    }
  );
};

export const useGetOrPermission = (
  orApp: string | boolean,
  onSuccess: (response: orRolesTypes[]) => void
) => {
  useQuery<orRolesTypes[]>(['or-permission'], getOrPermission, {
    onSuccess: (res) => {
      onSuccess(res);
    },
    enabled: !!orApp,
    onError: (response: any) => {
      notification.error({
        message: response?.message || 'Something went wrong while fetching OR Permissions.',
      });
    },
  });
};

type orCompany = {
  data: {
    BaseConfiguration: {
      company: number;
    }[];
  };
};

type orUsers = {
  data: {
    PaginatedUser: {
      data: {
        id: number;
        email: string;
        username: string;
        phone: string;
        complete_name: string;
        is_active: boolean;
        country_code: string;
        group: { name: string; id: number | null }[];
      }[];
    };
    limit: number;
    offset: number;
    count: number;
  };
};
