/* eslint-disable import/order */
import { Checkbox, Button } from 'antd';
import Cookies from 'js-cookie';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import * as z from 'zod';

import { Form, InputField } from '@/components/Form';
import { loginWithEmailAndPassword } from '@/features/auth';
import { useAuth } from '@/lib/auth';
import { showNotification } from '@/utils/scripts';
import storage from '@/utils/storage';

import '../assets/auth.scss';
import { IdcardOutlined, MailOutlined, UserOutlined } from '@ant-design/icons';

const schema = z
  .object({
    username: z
      .string({
        invalid_type_error: 'Please enter valid email',
      })
      .min(1, 'Email is required')
      .email(),
    password: z.string().min(1, 'Password is required'),
    accountId: z.string().min(1, 'Account ID is required'),
  })
  .superRefine((val, ctx) => {
    checkWhiteSpaces(val.password, 'password', ctx);
    checkWhiteSpaces(val.username, 'username', ctx);
    checkWhiteSpaces(val.accountId, 'accountId', ctx);
  });

type LoginValues = {
  username: string;
  password: string;
  accountId: string;
};

type LoginFormProps = {
  onSuccess: () => void;
};

export const LoginForm = ({ onSuccess }: LoginFormProps) => {
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [rememberMe, setRememberMe] = useState(true);
  // const [error, setError] = useState('');

  const { login } = useAuth();

  return (
    <div className="p-6 bg-white rounded-xl lg:w-[32vw] mx-6">
      <Form<LoginValues, typeof schema>
        onSubmit={async (values) => {
          const payload = {
            username: values.username.trim().toLowerCase(),
            password: values.password,
            realm: values.accountId,
          };
          let response;
          try {
            setIsLoggingIn(true);
            response = await loginWithEmailAndPassword(payload);
            if (response.data.login === true) {
              const accessToken = response?.data?.access_token;
              const refreshToken = response?.data?.refresh_token;
              rememberMe ? storage.setRemember(rememberMe) : storage.setRemember(rememberMe);
              storage.setToken(accessToken);
              storage.setRefreshToken(refreshToken);
              window.localStorage.setItem('name', JSON.stringify(response?.data?.name ?? 'Admin'));
              window.localStorage.setItem('realm', response?.data?.realm);
              rememberMe
                ? Cookies.set('accountId', response?.data?.realm, { expires: 99 })
                : Cookies.remove('accountId');
              storage.setApps(response?.data?.apps);
              login(response.data);
              setIsLoggingIn(false);
              onSuccess();
            } else {
              setIsLoggingIn(false);
              // setError(response.message);
              showNotification('error', response.message);
            }
          } catch (error) {
            setIsLoggingIn(false);
            showNotification('error', response?.message);
          }
        }}
        schema={schema}
      >
        {({ register, formState }) => (
          <>
            <div className="login_submit flex-col mb-[16px] md:mb-[0.85vw]">
              <div className="login__logo">
                <Link className="flex text-white pb-3" to="/">
                  <img
                    src={
                      'https://uploads-ssl.webflow.com/6225e3bcdcd38d2a25b1df9e/62f4f1d8b5f15173d11b5d40_logo-xstak.svg'
                    }
                    alt="xStak"
                  />
                </Link>
              </div>
              <p className="text-2xl text-[#262626] font-bold mb-0 text-center md:texgitt-3xl ">
                Hi, Welcome to XStak!
              </p>
              <p className="text-base mt-[12px] mb-0 text-gray-8 text-center">
                Empowering Your Retail Success
              </p>
            </div>
            <div className="relative">
              <InputField
                type="text"
                label="Account ID"
                className="h-10"
                suffix={<IdcardOutlined className="input_icon" />}
                placeholder="125abdf"
                error={formState.errors.accountId}
                defaultValue={Cookies.get('accountId') || ''}
                registration={register('accountId')}
              />
            </div>
            <div className="relative">
              <InputField
                type="text"
                autoComplete="email"
                label="Email Address"
                className="h-10"
                suffix={<MailOutlined className="input_icon" />}
                placeholder="abc@xyz.com"
                error={formState.errors.username}
                registration={register('username')}
              />
            </div>
            <InputField
              type="password"
              label="Password"
              className="h-10"
              placeholder="********"
              error={formState.errors.password}
              registration={register('password')}
            />
            <div className="mt-3">
              <Checkbox
                className="login-checkbox"
                onChange={() => {
                  setRememberMe(!rememberMe);
                }}
                defaultChecked
              >
                <span className="text-[14px] text-[#595959] md:text-[0.87vw]">Remember me</span>
              </Checkbox>
            </div>

            <div className="login_cta">
              <Link
                className=" flex justify-center text-[#2F54EB] hover:text-[#202f6b] text-[14px] md:text-[0.87vw] py-[8px] md:py-[16px]"
                to={'/auth/reset-password'}
              >
                Forgot your password?
              </Link>
            </div>

            <div>
              <Button
                loading={isLoggingIn}
                htmlType="submit"
                // type="primary"
                className="bg-black hover:bg-black hover:border-black border-black active:border-black active:bg-black active:text-white focus:border-black focus:bg-black focus:text-white text-white hover:text-white w-full rounded h-[40px] text-[16px] md:text-[1vw] flex items-center  justify-center"
                icon={<UserOutlined className="mb-[5px]" />}
              >
                Login
              </Button>
            </div>
          </>
        )}
      </Form>
    </div>
  );
};

export const checkWhiteSpaces = (
  val: string,
  path: 'username' | 'password' | 'accountId' | 'confirmPassword' | 'cnic' | 'phone',
  ctx: z.RefinementCtx
) => {
  const checkSpaces = new RegExp('\\s+');
  if (val && checkSpaces.test(val)) {
    return ctx.addIssue({
      code: z.ZodIssueCode.custom,
      path: [path],
      message: 'WhiteSpaces are not allowed',
    });
  }
};
