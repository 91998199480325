import React from 'react';

import { TreeSelectController } from '@/components/Form';

import { sdFields } from '../../constants';

const SDFields = ({ control, data, onChange }: sdFields) => {
  return (
    <>
      <TreeSelectController
        label="Select Brands and Stores For SD"
        name="sdBrands"
        control={control}
        placeholder="Select Roles"
        treeData={data}
        onChange={onChange}
      />
    </>
  );
};

export default SDFields;
