import * as React from 'react';

import { Head } from '@/components/Head';

type LayoutProps = {
  children: React.ReactNode;
  title: string;
};

export const Layout = ({ children, title }: LayoutProps) => {
  return (
    <div className="custom_layout min-h-screen flex items-center justify-center">
      <Head title={title} />
      <div>
        {/*  {size[0] >= 768 && (
          <div className="flex items-center justify-center flex-col  md:w-1/2 w-full bg-black">
            <div className="mb-10">
              <div className="flex items-center justify-center pb-[3.75vw]">
                <Link className="flex text-white" to="/">
                  <img
                    className="flex justify-center w-[13.79vw]"
                    src={'/images/xstaklogo.svg'}
                    alt="xStak"
                  />
                </Link>
              </div>
              <div className="flex justify-center items-center gap-[1.68vw]">
                <img className="h-[1.5vw]" alt="hypr" src={'/logos/HYPR-white.svg'} />
                <img className="h-[1.5vw]" alt="OE" src={'/logos/OE-white.svg'} />
                <img className="h-[1.5vw]" alt="shopdesk" src={'/logos/SD-white.svg'} />
                <img className="h-[1.5vw]" alt="omniretain" src={'/logos/OR-white.svg'} />
                <img className="h-[1.5vw]" alt="xpay" src={'/logos/Xpay-white.svg'} />
              </div>
              <div className="flex flex-col text-white items-center mt-[3.75vw]">
                <p className=" text-[1.5vw] font-semibold w-[15.25vw] text-center">
                  The operating system for retailers
                </p>
                <p className="text-[0.87vw] w-[17.62vw] text-center mt-[1vw]">
                  Seamlessly manage all your Omnichannel commerce, Marketing, Payments and Business
                  intelligence operations
                </p>
              </div>
            </div>
            {/* <h2 className="mt-3 text-center text-3xl font-extrabold text-white px-4">{title}</h2>  */}
        {/* </div>
        )} */}

        <div className="flex items-center justify-center flex-col">
          {/* <div className="login__logo">
            <Link className="flex text-white" to="/">
              <img
                src={
                  size[0] >= 768
                    ? 'https://uploads-ssl.webflow.com/6225e3bcdcd38d2a25b1df9e/62f4f1d8b5f15173d11b5d40_logo-xstak.svg'
                    : 'https://uploads-ssl.webflow.com/6225e3bcdcd38d2a25b1df9e/62f4f1d8b5f15173d11b5d40_logo-xstak.svg'
                }
                alt="xStak"
              />
            </Link>
          </div> */}

          <div>{children}</div>
          <div className="flex flex-col justify-center font-bold items-center mt-[40px] md:justify-start md:mt-[1.25vw]">
            <p className="service_policy text-sm md:text-[0.87vw] text-[#8C8C8C] text-center ">
              © 2023 XStak Inc.
            </p>
            <div className="service_policy gap-3 text-sm font-normal flex flex-row md:gap-[0.8vw] md:text-[0.87vw] text-[#2F54EB]">
              <a href="https://www.xstak.com/privacy-policy" target="_blank" rel="noreferrer">
                <p>Terms of Service </p>
              </a>
              <a href="https://www.xstak.com/privacy-policy" target="_blank" rel="noreferrer">
                <p> Privacy Policy</p>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
