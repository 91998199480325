import { Form } from 'antd';
import clsx from 'clsx';
import * as React from 'react';
import { FieldError } from 'react-hook-form';

type FieldWrapperProps = {
  label?: string;
  className?: string;
  children: React.ReactNode;
  error?: FieldError | undefined;
  description?: string;
  parentClassName?: string;
};

export type FieldWrapperPassThroughProps = Omit<FieldWrapperProps, 'className' | 'children'>;
export interface CheckBoxProps extends FieldWrapperPassThroughProps {
  checked?: boolean;
  handleClick?: (e: any) => void;
  className?: string;
  disabled?: boolean;
}

export const FieldWrapper = (props: FieldWrapperProps) => {
  const { label, className, error, children, parentClassName } = props;
  const displayError = typeof error === 'object' ? error?.message : error;
  return (
    <div className={`${parentClassName ? parentClassName : 'user_select_box'}`}>
      <Form.Item className={clsx('font-bold block mb-0', className)} label={label}>
        {children}
      </Form.Item>
      {error && (
        <div role="alert" className="text-sm font-semibold text-red-500 mb-1">
          {displayError}
        </div>
      )}
      {/* <label className={clsx('block md:text-[0.87vw] font-medium text-[#595959]', className)}>
        {label}
        <div>{children}</div>
      </label> */}
    </div>
  );
};
