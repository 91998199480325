import { Space, Tooltip, Tag } from 'antd';
import React from 'react';

import { Button } from '@/components/Elements';
import { Table } from '@/components/Table';

const appColors = {
  sd: '#1eb488',
  hypr: '#fa541c',
  oe: '#f5222D',
  or: '#faad14',
  xpay: '#4a5568',
};

function UserTables({
  data,
  handlePageChange,
  isLoading,
  totalNumberOfRecords,
  pageSize,
  edit,
  role,
  brand_id,
  store_id,
  btnLoading,
  userId,
}: {
  data: any;
  handlePageChange: (page: number, pageSize: number) => void;
  isLoading: boolean;
  totalNumberOfRecords: number;
  pageSize: number;
  role: string | null;
  edit: (args: any) => void;
  // runLoader: (args: string) => void;
  brand_id: string | Array<string>;
  store_id: string;
  btnLoading: boolean;
  userId: string;
}) {
  const columns = [
    {
      title: 'Sr.No',
      dataIndex: 'showId',
      key: 'showId',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'UserName/Email',
      dataIndex: 'username',
      key: 'userName',
    },
    {
      title: 'Role',
      key: 'role',
      render: (value: any) =>
        Array.isArray(value?.role) ? (
          value?.role.map((ro: any, i: React.Key | null | undefined) => {
            return (
              <Tag key={i} color={appColors[value?.app]}>
                {ro?.roleName}
              </Tag>
            );
          })
        ) : (
          <Tag color={appColors[value?.app]}>{value?.role}</Tag>
        ),
    },
    {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Status',
      render: (value: any) => (parseInt(value?.disabled) === 0 ? 'Disabled' : 'Enabled'),
      key: 'disable',
    },
    {
      title: 'Manage',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: any) => {
        const data = { rowData: record, role, brand_id, store_id };
        const isSuper = record?.is_super === '1' ? true : false;
        return (
          <Space>
            <>
              {isSuper ? (
                <Tooltip
                  // visible={true}
                  trigger={['hover', 'click']}
                  title="Super User Can't be Edited"
                  arrowPointAtCenter
                >
                  <Button disabled={true} className="table-btn" type="link">
                    Edit
                  </Button>
                </Tooltip>
              ) : (
                <Button
                  loading={userId === record.id ? btnLoading : false}
                  className="table-btn"
                  type="link"
                  onClick={() => {
                    edit(data);
                  }}
                >
                  Edit
                </Button>
              )}
            </>
          </Space>
        );
      },
    },
  ];
  return (
    <Table
      showSkeleton
      isLoading={isLoading}
      columns={columns}
      scroll={{ y: 290 }}
      data={data}
      pagingation={{
        totalNumberOfRecords,
        handlePageChange: handlePageChange,
        pageSize,
      }}
      emptyText={role ? undefined : 'Please select an app to find the users.'}
      // locale={{
      //   emptyText: <span>Select APP to display Users</span>,
      // }}
    />
  );
}

export default UserTables;
