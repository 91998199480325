import { AppProvider } from '@/providers/app';
import { AppRoutes } from '@/routes';
import './antd-button.css';
import './antd-checkbox.css';
import './antd-divider.css';
import './antd-dropdown.css';
import './antd-input.css';
import './antd-layout.css';
import './antd-menu.css';
import './antd-notification.css';
import 'antd/dist/antd.css';
import './index.scss';

function App() {
  console.log('testing deployment');
  return (
    <AppProvider>
      <AppRoutes />
    </AppProvider>
  );
}

export default App;
