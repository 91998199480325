import { Button, ButtonProps } from '@/components/Elements';
import './styles.scss';

type PageHeaderProps = {
  label?: string;
  buttons?: ButtonProps[];
};

export const PageHeader = ({ label, buttons }: PageHeaderProps) => {
  return (
    <div className="page-header bg-white">
      <h1 className="text-3xl font-extrabold pb-4 md:pb-0">{label}</h1>
      <div className="button_container">
        {buttons?.map(({ title, icon, onClick, type, ...props }, index) => (
          <Button type={type || 'primary'} key={index} icon={icon} onClick={onClick} {...props}>
            {title}
          </Button>
        ))}
      </div>
    </div>
  );
};
