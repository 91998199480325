import { Control, Controller, FieldValues, Path, RegisterOptions } from 'react-hook-form';

import { SelectFieldProps, SelectField } from '@/components/Form';

export type SelectControllerProps<FormValues> = {
  name: Path<FormValues>;
  defaultValue?: any;
  control: Control<FormValues>;
  rules?: RegisterOptions;
  onChange?: (x: any) => void;
} & Omit<SelectFieldProps, 'value'>;

export const SelectController = <FormValues extends FieldValues>({
  name,
  control,
  rules,
  label,
  defaultValue,
  className,
  disabled,
  isAsync,
  isSearchable,
  loadOptions,
  isMulti,
  placeholder,
  options,
  onChange,
  fieldNames,
  ...rest
}: SelectControllerProps<FormValues>) => {
  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      render={({ field, fieldState: { error } }) => {
        return (
          <SelectField
            label={label}
            value={field?.value}
            handleChange={(value) => {
              if (onChange) {
                onChange(value);
              }
              field.onChange(value);
            }}
            error={error?.message}
            className={className}
            placeholder={placeholder}
            disabled={disabled}
            options={options}
            isSearchable={isSearchable}
            isAsync={isAsync}
            loadOptions={loadOptions}
            isMulti={isMulti}
            fieldNames={fieldNames}
            ref={field.ref}
            getPopupContainer={(triggerNode: any) => triggerNode.parentElement}
            {...rest}
          />
        );
      }}
    />
  );
};
