import React from 'react';

// import { options } from '@/constants';
// import storage from '@/utils/storage';

import '../assets/dashboard.scss';
import Products from './products';

const DashboardComponent = (tabKey: any) => {
  let heading = '';
  if (tabKey?.tabKey == '2') {
    heading = 'Commerce Solutions';
  } else if (tabKey?.tabKey == '3') {
    heading = 'Marketing Solutions';
  } else if (tabKey?.tabKey == '4') {
    heading = 'Payment Solutions';
  }
  return (
    <div className="dashboard">
      <div className="dashboard__text">{<h4 className="h3 text-xl">{heading}</h4>}</div>
      <Products tabKey={tabKey} heading={heading} />
    </div>
  );
};

export default DashboardComponent;
